import Vue from 'vue';
import Environment from '@videsk/environment';

const stage = process.env.NODE_ENV || 'production';

const stages = {
  production: () => new Environment('global', 'https://api.videsk.io', 'https://api.videsk.io'),
  development: () => new Environment('global', 'http://localhost:3030', 'http://localhost:3030', 'development'),
  testing: () => new Environment('global', 'http://localhost:3030', 'http://localhost:3030', 'testing'),
  staging: () => new Environment('global', 'https://api.videsk.io', 'https://api.videsk.io', 'staging'),
};

const options = stages[stage]();

options.websocketOptions.params = {
  path: '/ws',
  forceNew: true,
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 600,
  transports: ['websocket'],
};

options.websocketOptions.namespaces = {
  console: 'console',
};


const agamottoEnvironments = {
  production: () => new Environment('agamotto', '', 'wss://agamotto.videsk.io'),
  development: () => new Environment('agamotto', '', 'ws://localhost:4000', 'development'),
  testing: () => new Environment('agamotto', '', 'ws://localhost:4000', 'testing'),
  staging: () => new Environment('agamotto', '', 'wss://agamotto.videsk.io', 'staging'),
};

const signalingEnvironments = {
  production: () => new Environment('signaling', '', 'wss://exchange.videsk.io'),
  development: () => new Environment('signaling', '', 'ws://localhost:6060', 'development'),
  testing: () => new Environment('signaling', '', 'ws://localhost:6060', 'testing'),
  staging: () => new Environment('signaling', '', 'wss://exchange.videsk.io', 'staging'),
};

const fileEnvironments = {
  production: () => new Environment('file-exchange', 'https://hole.videsk.io'),
  development: () => new Environment('file-exchange', 'http://localhost:3800', '', 'development'),
  testing: () => new Environment('file-exchange', 'http://localhost:3800', '', 'testing'),
  staging: () => new Environment('file-exchange', 'https://hole.videsk.io', '', 'staging'),
};

const optionsFile = fileEnvironments[stage]();
optionsFile.http.basePath = '/';
optionsFile.http.headers = new Headers({ 'Content-Type': 'application/octet-stream', 'X-Requested-With': 'videsk-sdk' });
optionsFile.http.endpoints = { upload: 'upload' };

Vue.prototype.$devOptions = options;
Vue.prototype.$agamottoOptions = agamottoEnvironments[stage]();
Vue.prototype.$signalingOptions = signalingEnvironments[stage]();
Vue.prototype.$fileOptions = optionsFile;

export default options;
