import Vue from 'vue';

class Timer extends EventTarget {
  constructor(workerPath = '') {
    super();
    this.formater = new Intl.RelativeTimeFormat(navigator.language, {
      localeMatcher: 'best fit',
      numeric: 'always',
      style: 'long'
    });
    this.worker = new Worker(workerPath);
    this.worker.addEventListener('message', (event) => {
      const { dayOfYear, time, error } = event.data;
      const eventName = error ? 'error' : 'updated';
      const customEvent = new CustomEvent(eventName, {
        detail: { dayOfYear, time, text: this.convertToRelativeTime(time) }
      });
      this.dispatchEvent(customEvent);
    });
  }

  start(interval = 60000) {
    if (this.started) return this.resume();
    this.started = true;
    this.worker.postMessage({ action: 'start', interval });
  }

  pause() {
    this.worker.postMessage({ action: 'pause' });
  }

  resume() {
    this.worker.postMessage({ action: 'resume' });
  }

  convertToRelativeTime(miliseconds) {
    const { converted, unit } = this.constructor.convertUnit(miliseconds);
    if (!unit) return this.formater.format(-1, 'minutes');
    return this.formater.format(Number(converted.toFixed(3)) * -1, unit);
  }

  static convertUnit(miliseconds) {
    if (typeof miliseconds === 'undefined') return {};
    const minute = 60000;
    const hour = minute * 60;
    const minutes = miliseconds / minute;
    const converted = minutes <= 60 ? minutes : miliseconds / hour;
    const unit = minutes <= 60 ? 'minutes' : 'hours';
    return { converted, unit };
  }
}

Vue.prototype.$timer = new Timer('/timer.js')

export default Vue.prototype.$timer;
