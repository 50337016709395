<template>
  <div class="form-container">
    <div class="form-header">
      <h2>{{ $t('meeting.contactFormTitle') }}</h2>
      <a-icon
        style="user-select: none;"
        :type="icon"
        theme="filled"
        @click="toggleVisibility"
      />
    </div>
    <a-divider />
    <div class="form-fields">
      <div
        class="form-field"
        v-for="(item, index) in form"
        :key="index"
      >
        <h3 style="user-select: none;">{{ item.label }}</h3>
        <p :class="{ monospace: !visible }">{{ visible ? item.value : new Array(item.value.length).join('*') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data: () => ({
    visible: true,
  }),
  computed: {
    icon() {
      return this.visible ? 'eye-invisible' : 'eye';
    },
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible;
    },
  }
};
</script>

<style>
.form-container {
  width: 100%;
}

.form-fields, .form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.7rem;
}

.form-fields .form-field:last-child {
  margin-bottom: 0;
}

.form-fields {
  margin: 0;
}

.form-fields h3 {
  margin-bottom: 0.7rem;
}

.form-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

p.monospace {
  font-family: monospace;
}
</style>
