export default {
  en: {
    checkEnvironment: 'Remember to have good lighting and that your camera is centered.',
    mediaUserError: 'We have detected an error when trying to access your camera or microphone',
    tryAgain: 'If this behavior repeats, try changing browsers such as Google Chrome, Edge, Safari, etc.',
    clickConnect: 'Click the blue connect button to get started',
    browserOffline: 'Your browser has detected that you are offline',
    expiredSession: 'Your session has expired, please log in again',
    totalNotifications: 'You have {total} {unit} unread',
    mic: 'Microphone',
    cam: 'Camera',
  },
  es: {
    checkEnvironment: 'Recuerda contar con una buena iluminación y que tu cámara esté centrada',
    mediaUserError: 'Hemos detectado un error al intentar acceder a tu cámara o micrófono',
    tryAgain: 'Si este comportamiento se repite intenta cambiar de navegador como Google Chrome, Edge, Safari, etc.',
    clickConnect: 'Haz clic en el botón azul conectar para comenzar',
    browserOffline: 'Tu navegador ha detectado que estás sin conexión',
    expiredSession: 'Tu sesión ha caducado, por favor inicia sesión nuevamente',
    totalNotifications: 'Tienes {total} {unit} sin leer',
    mic: 'Micrófono',
    cam: 'Cámara',
  },
  fr: {
    checkEnvironment: 'Assurez-vous d\'avoir un bon éclairage et que votre caméra soit centrée',
    mediaUserError: 'Nous avons détecté une erreur en essayant d\'accéder à votre caméra ou à votre microphone',
    tryAgain: 'Si ce problème persiste, essayez de changer de navigateur, comme Google Chrome, Edge, Safari, etc.',
    clickConnect: 'Cliquez sur le bouton bleu Connexion pour commencer',
    browserOffline: 'Votre navigateur a détecté que vous êtes hors connexion',
    expiredSession: 'Votre session a expiré, veuillez vous reconnecter',
    totalNotifications: 'Vous avez {total} {unit} non lus',
    mic: 'Microphone',
    cam: 'Caméra',
}

}
