<template>
  <a-row :gutter="24">
    <a-button @click="close" style="margin-bottom: 2rem;">
      {{ $t('close') }}
    </a-button>
    <a-row :gutter="24" v-show="!hideService">
      <a-col :span="24">
        <div class="form-item">
          <label>{{ $tc('entities.calendar', 1) }}</label>
          <a-select
            v-model="calendar"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in calendars"
              :key="index"
              :label="item.title"
              :value="item.id"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="24" v-show="calendars.length > 0">
      <a-col :span="24">
        <a-divider />
        <div class="form-item">
          <label>{{ $tc('words.month', 1) }}</label>
          <input
            class="month-picker"
            type="month"
            style="width: 100%;"
            :min="minDate"
            v-model="monthPicker"
          />
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="24" v-show="calendars.length > 0">
      <a-col :span="24">
        <div class="form-item">
          <a-divider />
          <label>{{ $tc('words.day', 1) }}</label>
          <div class="days-items">
            <div
              class="day-item"
              v-for="(item, index) in days"
              :key="index"
              :class="{ selected: selectedDay === new Intl.DateTimeFormat(language, { day: 'numeric', month: 'numeric', year: 'numeric', timeZone: 'UTC' }).format(new Date(item.utc)) }"
              @click="selectDay(item.utc)"
            >
              <span>{{ item.weekday }}</span>
              <span class="day-item-daynumber">{{ item.day }}</span>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="24">
        <div class="form-item">
          <a-divider />
          <label>{{ $tc('words.hour', 1) }}</label>
          <div class="hour-items">
            <div
              class="hour-item"
              v-for="(item, index) in hours"
              :key="index"
              :class="{ selected: new Date(item.utc).toLocaleDateString(language, { hour: 'numeric', minute: 'numeric' }) === new Date(currentHour).toLocaleDateString(language, { hour: 'numeric', minute: 'numeric' }) }"
              @click="selectHour(item.utc)"
            >
              {{ item.time }}
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="24" v-show="currentHour && !appointment">
        <div class="form-item">
          <a-divider />
          <label>{{ $tc('words.data', 2) }}</label>
          <div id="form-container"></div>
        </div>
      </a-col>
      <a-col :span="24" v-show="currentHour && !appointment">
        <div class="form-item">
          <label>{{ $tc('words.referrer', 1) }}</label>
          <a-select
            :default-active-first-option="true"
            v-model="link"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in links"
              :key="index"
              :label="item.url"
              :value="item.url"
            >
              {{ item.url }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>
      <a-col :span="24" v-show="currentHour && !appointment" style="margin-top: 2rem;">
        <div class="form-item">
          <label>{{ $tc('words.timezone', 1) }} ({{ $t('calendar.canceledBy.customer') }})</label>
          <a-alert :message="$t('calendar.timezoneFilter')" type="info" style="margin: 0.5rem 0 0.5rem 0;" show-icon />
          <a-alert :message="$t('calendar.timezoneWarning')" type="warning" style="margin: 0.5rem 0 1rem 0;" show-icon />
          <a-select
            :default-active-first-option="true"
            show-search
            @search="filterTimezone"
            @blur="resetTimezones"
            :filter-option="false"
            v-model="timezone"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) in timezones"
              :key="index"
              :label="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
      </a-col>
      <a-col :span="24" v-show="currentHour && !appointment">
        <div class="form-item">
          <a-divider />
          <a-button
            size="large"
            type="primary"
            @click="schedule"
            :loading="loading"
            :disabled="!timezone || !link"
          >
            {{ $t('calendar.schedule') }}
          </a-button>
        </div>
      </a-col>
      <a-col :span="24" v-show="currentHour && appointment">
        <div class="form-item">
          <a-divider />
          <a-button
            size="large"
            type="primary"
            @click="reschedule"
            :loading="loading"
          >
            {{ $t('calendar.reschedule') }}
          </a-button>
        </div>
      </a-col>
    </a-row>
  </a-row>
</template>

<script>
import Calendar from '@videsk/calendar-sdk';
import Form from '@videsk/forms';
import Timezones from '../plugins/timezones'

const endpoints = {
    services: 'services',
    daysByUser: userId => `users/${userId}/dates`,
    hoursByUser: userId => `users/${userId}/hours`,
    createUserAppointment: userId => `schedule/${userId}`,
};

export default {
  data() {
    return {
      calendars: [],
      days: [],
      hours: [],
      links: [],
      calendar: undefined,
      monthPicker: '',
      currentDate: undefined,
      currentHour: undefined,
      referrer: undefined,
      loading: false,
      hideService: false,
      appointment: undefined,
      link: '',
      timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezones: '',
    }
  },
  computed: {
    Timezones() {
      return Timezones
    },
    userId() {
      return this.$store.getters['global/id'];
    },
    monthDate() {
      const [year, month] = this.monthPicker.split('-').map(Number);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (year === currentYear && month === currentMonth) {
        let futureDate = new Date();
        futureDate.setMinutes(futureDate.getMinutes() + 5);
        return futureDate;
      } else {
        return new Date(year, month - 1, 1, 0, 0, 0, 0);
      }
    },
    minDate() {
      const month = new Date().getMonth() + 1;
      return `${new Date().getFullYear()}-${month < 10 ? `0${month}` : month }`;
    },
    selectedDay() {
      return new Intl.DateTimeFormat(this.language, { day: 'numeric', month: 'numeric', year: 'numeric', timeZone: 'UTC' }).format(new Date(this.currentDate));
    },
    language() {
      return window.navigator.language;
    },
  },
  mounted() {
    this.createCalendar();
    this.getCalendars();
    this.getLinks();
    this.resetTimezones();
  },
  methods: {
    resetTimezones() {
      this.timezones = Timezones.dictionary;
    },
    filterTimezone(event) {
      this.timezones = Timezones.find.city(event);
    },
    createCalendar() {
      const options = Calendar.defaultOptions();
      options.http.hostname = this.$devOptions.http.hostname;
      options.http.basePath = '/';
      options.http.endpoints = endpoints;
      this.calendarInstance = new Calendar(this.$authorization.getTokens('accessToken'), options);
      window.calendarCreate = this.calendarInstance;
    },
    async getCalendars() {
      const response = await this.$http.get('/services').catch(e => e);
      if (response instanceof Error) return this.$notification.error({ message: this.$t('calendar.errors.services') });
      this.calendars = response?.data?.data || [];
      this.calendar = this.calendars[0]?.id;
    },
    async getCalendarForm() {
      const response = await this.$http.get(`services/${this.calendar}/forms`).catch(e => e);
      if (response instanceof Error) return this.$notification.error({ message: this.$t('calendar.errors.form') });
      document.querySelector('videsk-form')?.remove();
      this.form = new Form({ target: document.getElementById('form-container') });
      if (response?.data?.form) this.form.render(response?.data?.form);
    },
    async getDays() {
      this.days = await this.calendarInstance.change('user').getDays(this.userId, this.monthDate.toISOString());
      this.currentDate = this.days[0]?.utc;
      this.getHours();
    },
    async getHours() {
      const output = new Date(this.currentDate) <= new Date() ? new Date(new Date().setMinutes(new Date().getMinutes() + 3)) : this.currentDate;
      this.hours = await this.calendarInstance.change('user').getHours(this.userId, output);
    },
    async getLinks() {
      const response = await this.$http.get('/referrers').catch(e => e);
      if (response instanceof Error) return this.$notification.error({ message: this.$t('calendar.errors.links') });
      this.links = response.data.data;
      if (!this.link) this.link = this.links[0]?.url;
    },
    async selectHour(hour) {
      this.currentHour = hour;
      await this.getCalendarForm();
      this.scrollBottom();
    },
    selectDay(day) {
      this.currentDate = day;
      this.getHours();
    },
    close() {
      this.$emit('close');
    },
    async schedule() {
      const { valid } = this.form.submit();
      if (!valid) return;
      this.loading = true;
      const payload = {
        startAt: this.currentHour,
        timezone: this.timezone,
        form: this.form.data,
        service: this.calendar,
        referrer: this.link,
      };
      const response = await this.$http.post(`schedule/${this.userId}`, payload).catch(e => e);
      this.loading = false;
      if (response instanceof Error) return this.$notification.error({ message: this.$t('calendar.errors.schedule'), description: response?.data?.message || 'unknown' });
      this.clean();
    },
    clean() {
      document.querySelector('input[type="month"]').value = '';
      document.querySelector('videsk-form')?.remove();
      this.currentHour = '';
      this.referrer = undefined;
      this.appointment = undefined;
      this.close();
    },
    setReschedule(appointment) {
      const { service } = appointment;
      this.appointment = appointment;
      this.hideService = true;
      this.calendar = service._id;
    },
    async reschedule() {
      this.loading = true;
      const payload = { date: this.currentHour };
      const response = await this.$http.post(`/schedule/reschedule/${this.appointment._id}`, payload).catch(e => e);
      this.loading = false;
      if (response instanceof Error) return this.$notification.error({ message: this.$t('calendar.errors.reschedule') });
      this.$notification.success({ message: this.$t('calendar.rescheduled') });
      this.$emit('rescheduled');
    },
    scrollBottom() {
      const node = document.querySelector('#appointment-create .ant-drawer-wrapper-body');
      node.scrollTop = node.scrollHeight;
    },
  },
  watch: {
    monthDate() {
      this.getDays();
    },
  }
}
</script>

<style>
.month-picker {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.calendar-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-item {
  display: flex;
  flex-direction: column;
}

.form-item label {
  font-weight: 600;
  margin-bottom: 1rem;
}

.ant-select-selection-selected-value {
  margin-top: 7px;
}

.days-items {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 1rem 0;
}

.day-item {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
  padding: 1rem;
  align-items: center;
  min-width: 62px;
  min-height: 62px;
}

.day-item:hover, .hour-item:hover, .day-item.selected, .hour-item.selected {
  background: blue;
  color: white;
}

.day-item-daynumber {
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 7px;
  font-weight: bold;
}

.hour-items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.hour-item {
  padding: 1rem;
  margin: 0.5rem;
}

.day-item, .hour-item {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #f2f2f2;
}

#appointment-create .ant-drawer-wrapper-body {
  scroll-behavior: smooth;
}
</style>
