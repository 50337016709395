<template>
  <div class="vd-subcontainer">
    <inbound-modal v-if="$store.getters['call/inbound']" />
    <div class="chronometer">
      <div style="display: flex; align-items: center; justify-content: center">
        <div
          class="session-status"
        />
        <div>{{ $t('timeConnected') }} {{ time }}</div>
      </div>
      <div
        style="margin-top: 5px;"
        class="jitter"
      >
        <small
          v-show="isCorrectNetwork"
          :class="{ high: jitter / 1000 > 1.3 }"
        >{{ $t('latency') }}: ~{{ jitter.toFixed(2) }}ms</small>
        <small
          v-show="!isCorrectNetwork"
          class="high"
        >No network</small>
      </div>
    </div>
    <div class="body-page">
      <router-view />
    </div>
    <div>
      <menu-videsk />
    </div>
    <call-container
      v-if="$store.getters['call/active']"
    />
  </div>
</template>

<script>
import NotificationAPI from '@/plugins/notification';
import InboundModal from '../../components/inbound-call.vue';
import CallContainer from '../call/index.vue';
import Menu from '../../components/menu.vue';

export default {
  components: {
    'inbound-modal': InboundModal,
    'call-container': CallContainer,
    'menu-videsk': Menu,
  },
  data: () => ({
    items: [
      { page: 'recordings', i18n: 'menu.recordings', icon: 'bar-chart' },
      { page: 'history', i18n: 'menu.history', icon: 'clock-circle' },
      { page: 'profile', i18n: 'menu.profile', icon: 'user' },
    ],
    time: '',
  }),
  computed: {
    jitter() {
      return this.$store.getters['global/jitter'];
    },
    isCorrectNetwork() {
      return this.$store.getters['global/isHTTPNetwork'];
    },
    available() {
      return this.$store.getters['global/available'];
    },
    disconnected() {
      return this.$store.getters['global/disconnected'];
    },
    client_out() {
      return this.$store.getters['call/client_out'];
    },
    client_leave() {
      return this.$store.getters['call/client_leave'];
    },
    reJoined() {
      return this.$store.getters['global/reJoined'];
    },
    customerTimeout() {
      return this.$store.getters['global/customerTimeout'];
    },
    customerJoined() {
      return this.$store.getters['global/customerJoined'];
    },
    customerDisconnected() {
      return this.$store.getters['global/customerDisconnected'];
    },
    connectionError() {
      return this.$store.getters['global/connectionError'];
    },
    callTimeout() {
      return this.$store.getters['global/callTimeout'];
    },
    errorAnswering() {
      return this.$store.getters['global/errorAnswering'];
    },
    hangupError() {
      return this.$store.getters['global/hangupError'];
    },
    customerLeaveBefore() {
      return this.$store.getters['global/customerLeaveBefore'];
    },
    // Calendar
    newAppointment() {
      return this.$store.getters['calendar/new'];
    },
    inMeeting() {
      return this.$store.getters['calendar/inMeeting'];
    },
    customerPermissions() {
      return this.$store.getters['global/customerPermissions'];
    },
  },
  watch: {
    disconnected(val) {
      setTimeout(() => {
        if (val) this.$Notify.error({ showClose: false, message: this.$t('status.offline') });
      }, 500);
    },
    client_out(status) {
      if (status) {
        this.$Notify.info({ message: this.$t('call.events.client_out'), duration: 7000 });
        this.$store.commit('call/client_out', false);
      }
    },
    client_leave(status) {
      if (status) {
        this.$Notify.warning({ message: this.$t('call.events.client_leave'), duration: 7000 });
        this.$store.commit('call/client_leave', false);
      }
    },
    reJoined(status) {
      if (!status) return;
      this.$Notify.info({ message: this.$t('notifications.callReconnected'), duration: 7000 });
      this.$store.commit('global/reJoined', false);
    },
    customerTimeout(status) {
      if (!status) return;
      this.$Notify.info({ message: this.$t('notifications.customerTimeout'), duration: 7000 });
      this.$store.commit('global/customerTimeout', false);
    },
    customerJoined(status) {
      if (!status) return;
      this.$Notify.info({ message: this.$t('notifications.customerJoined'), duration: 7000 });
      this.$store.commit('global/customerJoined', false);
    },
    customerDisconnected(status) {
      if (!status) return;
      this.$Notify.info({ title: this.$t('notifications.customerDisconnected'), message: `Motivo: ${status}`, duration: 7000 });
      this.$store.commit('global/customerDisconnected', false);
    },
    connectionError(status) {
      if (!status) return;
      this.$Notify.error({ message: this.$t('notifications.duplicatedSession'), duration: 7000 });
      this.$store.commit('global/connectionError', false);
    },
    callTimeout(status) {
      if (!status) return;
      this.$Notify.warning({ message: this.$t('notifications.answerTimeout'), duration: 10000 });
      this.$store.commit('global/callTimeout', false);
    },
    errorAnswering(status) {
      if (!status) return;
      this.$Notify.error({ message: this.$t('notifications.errorAnswering'), duration: 7000 });
      this.$store.commit('global/errorAnswering', false);
    },
    hangupError(status) {
      if (!status) return;
      this.$Notify.error({ message: this.$t('notifications.hangupError') });
      this.$store.commit('global/hangupError', false);
    },
    customerLeaveBefore(status) {
      if (!status) return;
      this.$Notify.warning({ message: this.$t('notifications.abandoned') });
      this.$store.commit('global/customerLeaveBefore', false);
    },
    // Calendar
    newAppointment(event) {
      if (!event) return;
      const { date } = event;
      const locale = new Intl.DateTimeFormat(window.navigator.language, { weekday: 'long', day: 'numeric', month: 'long' }).format(new Date(date));
      const localeTime = new Intl.DateTimeFormat(window.navigator.language, { hour: '2-digit', minute: '2-digit' }).format(new Date(date));
      this.$Notify.info({ message: this.$t('notifications.newMeeting', { locale, localeTime }), duration: 0, icon: 'icon-calendar' });
      new NotificationAPI().send(this.$t('notifications.newMeeting'), this.$t('notifications.newMeeting', { locale, localeTime }));
      this.$store.commit('calendar/new', null);
    },
    inMeeting(event) {
      if (!event) return;
      const { startAt } = event;
      const localeTime = new Intl.DateTimeFormat(window.navigator.language, { hour: '2-digit', minute: '2-digit' }).format(new Date(startAt));
      const message = this.$t('notifications.statusChangedMeeting', { localeTime });
      this.$Notify.info({ message, duration: 0, icon: 'calendar' });
      new NotificationAPI().send(this.$t('notifications.statusChangedMeetingTitle'), message);
    },
    customerPermissions(event) {
      if (!event) return;
      const { status, reason } = event;
      if (!status) return;
      this.$Notify.info({ message: reason });
      this.$store.commit('global/customerPermissions', null);

    }
  },
  async mounted() {
    this.time = this.$timer.formater.format(-1, 'minutes');
    this.$timer.addEventListener('updated', event => {
      const { text } = event.detail;
      this.time = text;
    });
    new NotificationAPI().permissions();
  },
};
</script>

<style>
  .vd-subcontainer {
    background: white;
    width: 100%;
    height: 100%;
  }

  .body-page {
    width: 100%;
    height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    overflow: auto;
  }

  .chronometer {
    position: absolute;
    top: 0;
    padding: 7px 20px;
    border-radius: 0 0 10px 10px;
    text-align: center;
    border: 1px solid #d3d7de;
    border-top: none;
    display: flex;
    flex-direction: column;
    color: gray;
    background: white;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    min-width: 320px;
  }

  .chronometer .session-status {
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.8em;
    border-radius: 100%;
  }

  .session-status.green {
    background: #52c41a;
  }

  .session-status.red {
    background: #d9363e;
  }

  .session-status.yellow {
    background: #faad14;
  }

  .jitter .high {
    background: red;
    color: white;
    font-weight: bold;
  }
</style>
