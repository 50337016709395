export default {
  en: {
    title: {
      inbound: 'Inbound Call',
      invitation: 'Inbound Invitation',
      type_call: {
        direct: 'Direct transfer by {name}',
      },
    },
    transfer: {
      empty: {
        agents: 'Not agents available for transfer.',
        departments: 'Something wrong happen, try close this window and open.',
      },
      title: 'Transfer call',
      subtitle: 'Select a department or agent to transfer',
      transferring: 'Transferring...',
      transfer_to: 'You will transfer to {name}',
      transfer_to_subtitle: 'To confirm the transfers do click on Transfer button.',
      successful: 'Call transferred successful, ending call in 10 seconds.',
      failed: '{name} can\'t answer the call',
      search: {
        agent: 'Search agent',
        department: 'Search department',
      },
    },
    menu: {
      transfer: 'Transfer',
      hold: 'Active On Hold',
      search: 'Search data',
      add: 'Request agent',
      form: 'Forms',
    },
    form: {
      mandatoryTitle: 'Complete the required fields or verify that they are correct.',
      mandatoryDescription: 'To hang up the call you must complete the required fields.',
      comments: 'Fill with information about the call.',
    },
    invite: {
      title: 'Invite agent',
      subtitle: 'Select agent/supervisor and invite for join to the call',
      waiting: 'Waiting response...',
      successful: '{name} was accepted the invitation.',
      failed: '{name} was rejected the invitation.',
      alert: {
        title: 'You will invite to {name}',
        subtitle: 'To confirm please click on Invite button.',
      },
      hangup: 'The guest agent hung up.',
    },
    tooltip: {
      transfer: 'Transfer',
      invite: 'Invite',
      holdon: 'Hold on',
      rec: 'Record',
      stop: 'Stop',
      sharefiles: 'Share files',
      info: 'Call info',
      chat: 'Chat',
      capture: 'Capture image',
      kick: 'Disconnect customer',
      apps: 'Apps',
    },
    events: {
      client_out: 'The customer has canceled the call in the queue.',
      client_leave: 'The customer has abandoned the call, you can end the call manually.',
    },
    beamport: {
      capture: {
        title: 'Capture images?',
        subtitle: 'This will initiate a high-speed connection for screenshot transfer.',
      },
      transfer: {
        title: 'Do you want to exchange files with the client?',
        subtitle: 'This will initiate a high-speed connection for file transfer with the client.',
      },
      connect: 'Connect',
      connecting: 'Connecting...',
    },
    saveFiles: {
      errorSaving: 'An error occurred while trying to save the file',
      noAccess: 'You do not have access to upload images.',
    },
    tags: {
      title: 'Tags',
      placeholder: 'Select a tag',
    },
    infoCall: {
      ip: 'IP Address',
      website: 'Website',
      detailsUrl: 'Open details',
      country: 'Country',
      region: 'State',
      city: 'City',
      browser: 'Browser',
      os: 'Operative system',
      language: 'Language',
      device: 'Device',
      saveAutomatic: 'Automatic saved: {time}',
      modalUrlDetails: 'URL details',
    },
    kickModal: {
      title: 'Are you sure?',
      description: 'You will disconnect to the customer, don\'t allowing reconnect again.',
    },
    integrationData: 'Queue integration data',
    notifyQMS: 'Notify in screen',
    notifyTicket: 'Notify to the ticket',
    source: 'Call source',
  },
  es: {
    title: {
      inbound: 'Llamada Entrante',
      invitation: 'Invitación Entrante',
      type_call: {
        direct: 'Transferencia directa por {name}',
      },
    },
    transfer: {
      empty: {
        agents: 'No hay agentes disponibles para transferir.',
        departments: 'Algo ha ocurrido, intenta cerrar esta ventana y abrir.',
      },
      title: 'Transferir llamada',
      subtitle: 'Seleccione un departamento o agente a transferir',
      transferring: 'Transfiriendo...',
      transfer_to: 'Vas a transferir a {name}',
      transfer_to_subtitle: 'Para confirmar la transferencia haz clic en el botón Transferir.',
      successful: 'Llamada transferida con exito, terminando en 10 segundos.',
      failed: '{name} no puede contestar la llamada.',
      search: {
        agent: 'Buscar agente',
        department: 'Buscar departamento',
      },
    },
    menu: {
      transfer: 'Transferir',
      hold: 'Dejar en espera',
      search: 'Buscar datos',
      add: 'Invitar agente',
      form: 'Forms',
    },
    form: {
      mandatoryTitle: 'Completa los campos requeridos o verifica que estén correctos.',
      mandatoryDescription: 'Para colgar el llamado debes completar los campos requeridos.',
      comments: 'Rellene con información sobre la llamada.',
    },
    invite: {
      title: 'Invitar a un agente',
      subtitle: 'Seleccione agente / supervisor e invite a unirse a la llamada',
      waiting: 'Esperando respuesta...',
      successful: '{name} aceptó la invitación.',
      failed: '{name} rechazó la invitación.',
      alert: {
        title: 'Invitarás a {name}',
        subtitle: 'Haz clic en el botón Invitar para confirmar.',
      },
      hangup: 'El agente invitado ha colgado.',
    },
    tooltip: {
      transfer: 'Transferir',
      invite: 'Invitar',
      holdon: 'En espera',
      rec: 'Grabar',
      stop: 'Detener',
      sharefiles: 'Enviar archivos',
      info: 'Información de la llamada',
      chat: 'Chat',
      capture: 'Capturar imagen',
      kick: 'Desconectar al cliente',
      apps: 'Aplicaciones',
    },
    events: {
      client_out: 'El cliente ha cancelado la llamada desde la espera.',
      client_leave: 'El cliente ha abandonado la llamada, puede terminar la llamada manualmente.',
    },
    beamport: {
      capture: {
        title: '¿Deseas capturar imágenes?',
        subtitle: 'Esto iniciará una conexión de alta velocidad para la transferencia de capturas.',
      },
      transfer: {
        title: '¿Deseas intercambiar archivos con el cliente?',
        subtitle: 'Esto iniciará una conexión de alta velocidad para la transferencia de archivos con el cliente.',
      },
      connect: 'Conectar',
      connecting: 'Conectando...',
    },
    saveFiles: {
      errorSaving: 'Ha ocurrido un error al intentar guardar el archivo',
      noAccess: 'No tienes acceso a subir imágenes.',
    },
    tags: {
      title: 'Tags',
      placeholder: 'Select a tag',
    },
    infoCall: {
      ip: 'Dirección IP',
      website: 'Sitio web',
      detailsUrl: 'Abrir detalles',
      country: 'País',
      region: 'Region',
      city: 'Ciudad',
      browser: 'Navegador',
      os: 'Sistema operativo',
      language: 'Idioma',
      device: 'Dispositivo',
      saveAutomatic: 'Guardado automático: {time}',
      modalUrlDetails: 'Información URL',
    },
    kickModal: {
      title: 'Deseas confirmar la acción?',
      description: 'Desconectarás al cliente de la llamada sin posibilidad de volver a conectase.',
    },
    integrationData: 'Datos de la fila (QMS)',
    notifyQMS: 'Notificar en pantalla',
    notifyTicket: 'Notificar al ticket',
    source: 'Origen de la llamada',
  },
  fr: {
    title: {
      inbound: 'Appel entrant',
      invitation: 'Invitation entrante',
      type_call: {
        direct: 'Transfert direct par {name}',
      },
    },
    transfer: {
      empty: {
        agents: 'Aucun agent disponible pour transférer.',
        departments: 'Un problème est survenu, essayez de fermer cette fenêtre et de la rouvrir.',
      },
      title: 'Transférer l\'appel',
      subtitle: 'Sélectionnez un département ou un agent pour le transfert',
      transferring: 'Transfert en cours...',
      transfer_to: 'Vous allez transférer à {name}',
      transfer_to_subtitle: 'Pour confirmer le transfert, cliquez sur le bouton Transférer.',
      successful: 'Appel transféré avec succès, fin de l\'appel dans 10 secondes.',
      failed: '{name} ne peut pas répondre à l\'appel.',
      search: {
        agent: 'Rechercher un agent',
        department: 'Rechercher un département',
      },
    },
    menu: {
      transfer: 'Transférer',
      hold: 'Mettre en attente',
      search: 'Rechercher des données',
      add: 'Inviter un agent',
      form: 'Formulaires',
    },
    form: {
      mandatoryTitle: 'Complétez les champs requis ou vérifiez qu\'ils sont corrects.',
      mandatoryDescription: 'Pour raccrocher, vous devez compléter les champs requis.',
      comments: 'Remplissez avec des informations sur l\'appel.',
    },
    invite: {
      title: 'Inviter un agent',
      subtitle: 'Sélectionnez un agent / superviseur et invitez-le à rejoindre l\'appel',
      waiting: 'En attente de réponse...',
      successful: '{name} a accepté l\'invitation.',
      failed: '{name} a refusé l\'invitation.',
      alert: {
        title: 'Vous allez inviter {name}',
        subtitle: 'Cliquez sur le bouton Inviter pour confirmer.',
      },
      hangup: 'L\'agent invité a raccroché.',
    },
    tooltip: {
      transfer: 'Transférer',
      invite: 'Inviter',
      holdon: 'En attente',
      rec: 'Enregistrer',
      stop: 'Arrêter',
      sharefiles: 'Envoyer des fichiers',
      info: 'Informations sur l\'appel',
      chat: 'Chat',
      capture: 'Capturer une image',
      kick: 'Déconnecter le client',
      apps: 'Applications',
    },
    events: {
      client_out: 'Le client a annulé l\'appel depuis l\'attente.',
      client_leave: 'Le client a quitté l\'appel, vous pouvez terminer l\'appel manuellement.',
    },
    beamport: {
      capture: {
        title: 'Souhaitez-vous capturer des images ?',
        subtitle: 'Cela démarrera une connexion à haute vitesse pour le transfert des captures.',
      },
      transfer: {
        title: 'Souhaitez-vous échanger des fichiers avec le client ?',
        subtitle: 'Cela démarrera une connexion à haute vitesse pour le transfert de fichiers avec le client.',
      },
      connect: 'Connecter',
      connecting: 'Connexion en cours...',
    },
    saveFiles: {
      errorSaving: 'Une erreur est survenue lors de l\'enregistrement du fichier',
      noAccess: 'Vous n\'avez pas accès pour télécharger des images.',
    },
    tags: {
      title: 'Étiquettes',
      placeholder: 'Sélectionner une étiquette',
    },
    infoCall: {
      ip: 'Adresse IP',
      website: 'Site web',
      detailsUrl: 'Ouvrir les détails',
      country: 'Pays',
      region: 'Région',
      city: 'Ville',
      browser: 'Navigateur',
      os: 'Système d\'exploitation',
      language: 'Langue',
      device: 'Appareil',
      saveAutomatic: 'Sauvegarde automatique : {time}',
      modalUrlDetails: 'Informations URL',
    },
    kickModal: {
      title: 'Souhaitez-vous confirmer l\'action ?',
      description: 'Vous déconnecterez le client de l\'appel sans possibilité de se reconnecter.',
    },
    integrationData: 'Données de ligne (QMS)',
    notifyQMS: 'Notifier à l\'écran',
    notifyTicket: 'Notifier le ticket',
    source: 'Origine de l\'appel',
}

};
