/* eslint-disable linebreak-style */
import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/plugins/vue-router/routes';

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  routes,
});

export default router;
