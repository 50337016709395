<template>
  <div class="info-call-container">
    <div
      class="tags"
      v-show="existTags"
    >
      <label>{{ $t('call.tags.title') }}</label>
      <a-select
        mode="multiple"
        :placeholder="$t('call.tags.placeholder')"
        v-model="selectedTags"
        option-filter-prop="children"
        :filter-option="searchTags"
        @change="saveTags"
      >
        <a-select-option
          v-for="item in availableTags"
          :key="item.name"
          :value="item._id"
          :label="item.label"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <div id="call-form">
      <div
        class="form-row"
        v-show="validForm"
      />
     <a-collapse accordion style="margin: 1rem 0 2rem 0;" activeKey="1">
       <a-collapse-panel key="1" :header="$t('call.source')" v-if="details.segment">
         <div class="source-details">
           <a-tooltip>
             <template slot="title">{{ $tc('words.segment', 1) }}</template>
             <div class="source-item" v-if="details.segment"><a-icon type="apartment" /> <a-divider type="vertical" /> {{ details.segment.name }}</div>
           </a-tooltip>
           <a-tooltip>
             <template slot="title">{{ $tc('words.branch', 1) }}</template>
             <div class="source-item" v-if="details.branch"><a-icon type="bank" /> <a-divider type="vertical" /> {{ details.branch.label }}</div>
           </a-tooltip>
             <a-tooltip>
             <template slot="title">{{ $t('words.ticket') }}</template>
             <div class="source-item" v-if="details.ticketId"><a-icon type="number" /> <a-divider type="vertical" /> <strong>{{ details.ticketId }}</strong></div>
           </a-tooltip>
             <a-tooltip>
             <template slot="title">{{ $t('words.poc') }}</template>
             <div class="source-item" v-if="details.poc"><a-icon type="desktop" /> <a-divider type="vertical" /> {{ details.poc.label }}</div>
           </a-tooltip>
         </div>
       </a-collapse-panel>
       <a-collapse-panel key="2" :header="`${$t('call.notifyQMS')} (QMS)`" v-if="details.poc">
         <a-button type="primary" @click="notifyTicket" :loading="notifying">{{ $t('call.notifyTicket') }}</a-button>
       </a-collapse-panel>
       <a-collapse-panel key="3" :header="$t('call.integrationData')" v-if="details.integrationData">
         <pre style="padding: 0.4rem 0.5rem;" v-if="!integrationDataJSON">
           <code>{{ details.integrationData }}</code>
         </pre>
         <div class="url-params-container" v-if="integrationDataJSON">
           <div
             class="url-params-item"
             v-for="(value, key) in integrationDataJSON"
             :key="key"
           >
             <div class="url-params-key">
               {{ key }}
             </div>
             <div class="url-params-value">
               <pre style="padding: 0.4rem 0.5rem;"><code>{{ value }}</code></pre>
             </div>
           </div>
         </div>
       </a-collapse-panel>
     </a-collapse>
      <div
        class="form-not-exist"
      >
        <div class="extra-data-row">
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.ip') }}</label>
            <span :title="extraData.ip">{{ extraData.ip }}</span>
          </div>
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.website') }}</label>
            <span :title="extraData.referrer">{{ extraData.referrer }}</span>
            <a-button
              style="margin-top: 5px;"
              @click="urlModal = true"
            >
              {{ $t('call.infoCall.detailsUrl') }}
            </a-button>
          </div>
        </div>
        <div class="extra-data-row">
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.country') }}</label>
            <span
              style="display: flex;"
              :title="extraData.countryName"
            >{{ extraData.countryName }} <img
              v-show="extraData.countryName"
              alt="country flag"
              style="height: 17px; margin-left: 5px;"
              :src="`https://cdn.ipregistry.co/flags/twemoji/${(extraData.countryCode || '').toLowerCase()}.svg`"
            ></span>
          </div>
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.region') }}</label>
            <span :title="extraData.regionName">{{ extraData.regionName }}</span>
          </div>
        </div>
        <div class="extra-data-row">
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.city') }}</label>
            <span :title="extraData.cityName">{{ extraData.cityName }}</span>
          </div>
          <div class="extra-data-item">
            <label>ISP / Operador</label>
            <span :title="extraData.carrierName">{{ extraData.carrierName }}</span>
          </div>
        </div>
        <div class="extra-data-row">
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.browser') }}</label>
            <span :title="`${extraData.browserName} ${extraData.browserVersion}`">{{ extraData.browserName }} {{ extraData.browserVersion }}</span>
          </div>
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.os') }}</label>
            <span :title="`${extraData.osName} ${extraData.osVersion}`">{{ extraData.osName }} {{ extraData.osVersion }}</span>
          </div>
        </div>
        <div class="extra-data-row">
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.device') }}</label>
            <span :title="$t(`devices.${extraData.deviceType}`)">{{ $t(`devices.${extraData.deviceType}`) }}</span>
          </div>
          <div class="extra-data-item">
            <label>{{ $t('call.infoCall.language') }}</label>
            <span :title="extraData.langNative">{{ extraData.langNative }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="call-comments-agent">
      <span>{{ $t('comments') }}:</span>
      <a-textarea
        :rows="4"
        v-model="comments"
        :placeholder="$t('call.form.comments')"
        @keydown="handleChange"
      />
      <div class="time-ago">
        {{ $t('call.infoCall.saveAutomatic', { time: saveAgo }) }}
      </div>
    </div>
    <a-modal
      :title="$t('call.infoCall.modalUrlDetails')"
      v-model="urlModal"
      centered
      :closable="true"
      :mask-closable="true"
    >
      <div class="full-url">
        <pre><code>{{ extraData.referrer }}</code></pre>
      </div>
      <div class="header-separator">
        Valores
      </div>
      <div class="url-params-container">
        <div
          class="url-params-item"
          v-for="(item, index) in params"
          :key="index"
        >
          <div class="url-params-key">
            {{ item.key }}
          </div>
          <div class="url-params-value">
            <pre><code>{{ item.value }}</code></pre>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="urlModal = false">
          {{ $t('words.close') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import sanitize from 'dompurify';
import HumanizeDate from '@videsk/humanize-date';
import '@videsk/forms/dist/style.min.css';
import FormsSDK from '@videsk/forms';

export default {
  name: 'callinfo',
  data: () => ({
    urlModal: false,
    formExist: false,
    comments: '',
    debounce: null,
    now: null,
    form: null,
    saveAt: null,
    saveAgo: '',
    interval: null,
    params: [],
    selectedTags: [],
    availableTags: [],
    notifying: false,
  }),
  computed: {
    details() {
      return this.$store.getters['call/callData'] || {};
    },
    integrationDataJSON() {
      return (typeof this.details.integrationData === 'object') && this.details.integrationData;
    },
    validForm() {
      const form = this.$store.getters['call/form'];
      return form && Array.isArray(form) && form.length > 0;
    },
    commentsUpdate() {
      return this.$store.getters['global/commentsTime'];
    },
    updateText() {
      return (this.now && 'from' in this.now && this.commentsUpdate) ? this.now.from(this.commentsUpdate) : '';
    },
    showUpdate() {
      return this.updateText.length > 0;
    },
    extraData() {
      return this.$store.getters['call/extraData'] || {};
    },
    existTags() {
      return this.availableTags.length > 0;
    },
    socket() {
      return this.$store.getters['global/socket'];
    }
  },
  mounted() {
    this.debounce = _debounce(this.saveComment.bind(this), 500);
    if (this.validForm) this.loadForm();
    this.comments = this.$store.getters['call/comments'];
    this.parseURL();
    this.setTags();
    this.loadTags();
    this.$store.getters['global/socket'].on('comments:updated', () => {
      this.saveAt = Date.now();
      if (!this.interval) this.runInterval();
    });
  },
  methods: {
    notifyTicket() {
      this.notifying = true;
      this.socket?.emit('qms:notify');
      setTimeout(() => (this.notifying = false), 3000);
    },
    loadTags() {
      this.availableTags = this.$store.getters['call/tags'];
    },
    setTags() {
      const selectedTags = this.$store.getters['call/selectedTags'];
      if (selectedTags.length < 1) return;
      this.selectedTags = selectedTags;
    },
    parseURL() {
      const { referrer } = this.extraData;
      if (!referrer) return;
      const urlSplit = referrer.split('?');
      if (urlSplit.length < 1) return;
      this.convertParamsToArray(new URLSearchParams(urlSplit[1]));
    },
    runInterval() {
      this.interval = this.$timers.setInterval(() => {
        this.saveAgo = new HumanizeDate().dates(this.saveAt).ago('minutes');
      }, 1000);
    },
    loadForm() {
      const data = this.$store.getters['call/form'];
      this.form = new FormsSDK({ target: document.querySelector('.form-row') });
      this.form.render(data, true);
    },
    handleChange() {
      this.debounce();
    },
    saveComment() {
      this.$store.getters['global/socket'].emit('comments', {
        comments: sanitize.sanitize(this.comments),
      });
    },
    convertParamsToArray(queries) {
      const keys = queries.keys();
      for (const key of keys) {
        this.params.push({ key, value: queries.get(key) });
      }
    },
    searchTags(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    saveTags(value) {
      const payload = { tags: value };
      this.$store.getters['global/socket'].emit('set:tags', payload);
    },
  },
};
</script>

<style>
.info-call-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 5%;
}

#call-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form-not-exist {
  width: 100%;
  height: auto;
  padding: 0;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  text-align: center;
  font-size: 0.9em;
}

.form-not-exist i {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.call-comments-agent {
  width: 100%;
  height: auto;
}

.call-comments-agent span {
  margin-bottom: 10px;
  display: block;
}

.time-ago {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  margin-top: 5px;
}

.extra-data-row {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 15px;
}

.extra-data-item {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 5px;
}

.extra-data-item label {
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
  text-align: left;
}

.extra-data-item span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.form-row {
  width: 100%;
  height: auto;
  padding-bottom: 1rem;
}

.header-separator {
  width: 100%;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 1rem 0;
}

.url-params-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.url-params-key {
  width: 20%;
  font-weight: 700;
  color: #2d2d2d;
}

.url-params-key:first-letter {
  text-transform: uppercase;
}

.url-params-value {
  width: 75%;
  background: #edeef1;
  color: #2f2f2f;
  border-radius: 5px;
}

pre {
  background: #dde1e7;
  color: #444443;
  padding: 0.8rem 0.6rem;
  border-radius: 5px;
}

pre code {
  margin: 0;
  padding: 0;
}

.tags {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #d7d7d7;
}

.tags label {
  margin-bottom: 10px;
  font-weight: 600;
  color: #484848;
}

.source-details {
  display: flex;
  flex-direction: column;
}

.source-details .source-item {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.source-details div:first-child {
  margin-top: 0;
}

.source-details div:last-child {
  margin-bottom: 0;
}

.source-details .source-item .separator {
  margin: 0 1rem;
}

.source-item {
  width: fit-content;
}
</style>
