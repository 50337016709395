<template>
  <videsk-chat
    class="active"
    ref="v-chat"
  />
</template>

<script>
export default {
  name: 'chat',
  mounted() {
    document.addEventListener('message:received', event => {
      const { message } = event.detail;
      this.$refs['v-chat'].add(message, false, false);
    });
  },
};
</script>

<style>
  videsk-chat {
    width: 100%;
    height: 100%;
  }

  videsk-chat::part(close-button) {
    opacity: 0;
  }
</style>
