const state = {
  inbound: false,
  active: false,
  id: null,
  segment: '',
  previousSegment: '',
  priority: false,
  time_in_queue: 0,
  invitation: false,
  minimized: false,
  transferred: false,
  agent: '',
  user: 'agent',
  call: {},
  transfer: {
    agent: null,
    segment: null,
  },
  invite_agent: null,
  client_out: false,
  client_leave: false,
  fileShare: null,
  isFileShareActive: false,
  extraData: {},
  form: [],
  comments: '',
  tags: [],
  selectedTags: [],
  signalingToken: null,
  recordingsSettings: {},
  agentForm: undefined,
  formInstance: null,
  recordingToken: '',
  mandatoryTags: false,
  inboundData: {},
  callData: {},
};

const mutations = {
  inbound(state, value) {
    state.inbound = value;
    if (!value) state.inboundData = {};
  },
  active(state, value) {
    state.active = value;
  },
  id(state, value) {
    state.id = value;
  },
  segment(state, value) {
    state.segment = value;
  },
  priority(state, value) {
    state.priority = value;
  },
  minimized(state, value) {
    state.minimized = value;
  },
  user(state, value) {
    state.user = value;
  },
  call(state, value) {
    state.call = value;
  },
  time_in_queue(state, value) {
    state.time_in_queue = value;
  },
  invitation(state, value) {
    state.invitation = value;
  },
  transfer(state, value) {
    state.transfer = value;
  },
  transferred(state, value) {
    state.transferred = value;
  },
  agent(state, value) {
    state.agent = value;
  },
  client_out(state, value) {
    state.client_out = value;
  },
  client_leave(state, value) {
    state.client_leave = value;
  },
  invite_agent(state, value) {
    state.invite_agent = value;
  },
  previousSegment(state, value) {
    state.previousSegment = value;
  },
  fileShare(state, value) {
    state.fileShare = value;
  },
  isFileShareActive(state, value) {
    state.isFileShareActive = value;
  },
  extraData(state, value) {
    state.extraData = value;
  },
  form(state, value) {
    state.form = value;
  },
  comments(state, value) {
    state.comments = value;
  },
  tags(state, value) {
    state.tags = value;
  },
  selectedTags(state, value) {
    state.selectedTags = value;
  },
  signalingToken(state, value) {
    state.signalingToken = value;
  },
  recordingsSettings(state, value) {
    state.recordingsSettings = value;
  },
  agentForm(state, value) {
    state.agentForm = value;
  },
  formInstance(state, value) {
    state.formInstance = value;
  },
  recordingToken(state, value) {
    state.recordingToken = value;
  },
  mandatoryTags(state, value) {
    state.mandatoryTags = value;
  },
  inboundData(state, value) {
    if (typeof value === 'object') state.inboundData = value;
  },
  callData(state, value) {
    if (typeof value === 'object') state.callData = value;
  },
};

const actions = {
  answer({ commit, dispatch, rootState }) {
    // commit('forceRerender', Math.random());
    document.dispatchEvent(new Event('inbound:pause'));
    rootState.global.socket.emit('answer');
    commit('inbound', false);
  },
  dismiss({ commit, dispatch, rootState }) {
    document.dispatchEvent(new Event('inbound:pause'));
    rootState.global.socket.emit('dismiss');
    commit('inbound', false);
    commit('active', false);
  },
  end({ rootState, dispatch, rootGetters }) {
    const status = rootGetters['global/statusHangup'];
    document.dispatchEvent(new Event('inbound:pause'));
    rootState.global.socket.emit('hangup', { status });
  },
  transfer({ rootState }) {
    const response = {};
    const { agent, segment } = state.transfer;
    if (agent) response.agent = agent; else response.segment = segment;
    const entity = (agent) ? 'agent' : 'segment';
    rootState.global.socket.emit(`call:transfer:${entity}`, response);
  },
  invite({ rootState }, agent) {
    rootState.global.socket.emit('call:invite:agent', { agent: agent || state.invite_agent });
  },
  CleanEndedCall({ commit }) {
    window.isCallActive = false;
    commit('active', false);
    commit('callData', {});
    commit('id', null);
    commit('calendar/onMeeting', false, { root: true });
    commit('inbound', false);
    commit('segment', '');
    commit('previousSegment', '');
    commit('time_in_queue', 0);
    commit('minimized', false);
    commit('transfer', { agent: '', segment: '' });
    commit('call', {});
    commit('transferred', false);
    commit('agent', null);
    commit('form', null);
    commit('agentForm', null);
    commit('formInstance', null);
    commit('comments', '');
    commit('isFileShareActive', false);
    commit('global/transfer_successful', { status: false, reason: '' }, { root: true });
    commit('global/transferAgentFail', { status: false, reason: '' }, { root: true });
    commit('global/transferSegmentFail', { status: false, reason: '' }, { root: true });
    commit('global/invitation_successful', { status: false, reason: '' }, { root: true });
    commit('global/invitation_failed', { status: false, reason: '' }, { root: true });
    commit('global/statusHangup', '', { root: true });
    commit('recordingToken', '');
    if (!state.fileShare) return;
    if ('channel' in state.fileShare) state.fileShare.disconnect();
    commit('fileShare', null);
  },
};

const getters = {
  inbound: state => state.inbound,
  active: state => state.active,
  id: state => state.id,
  segment: state => state.segment,
  priority: state => state.priority,
  minimized: state => state.minimized,
  user: state => state.user,
  call: state => state.call,
  time_in_queue: state => state.time_in_queue,
  transfer: state => state.transfer,
  invitation: state => state.invitation,
  transferred: state => state.transferred,
  agent: state => state.agent,
  client_out: state => state.client_out,
  client_leave: state => state.client_leave,
  invite_agent: state => state.invite_agent,
  previousSegment: state => state.previousSegment,
  fileShare: state => state.fileShare,
  isFileShareActive: state => state.isFileShareActive,
  extraData: state => state.extraData,
  form: state => state.form,
  comments: state => state.comments,
  tags: state => state.tags,
  selectedTags: state => state.selectedTags,
  signalingToken: state => state.signalingToken,
  recordingsSettings: state => state.recordingsSettings,
  agentForm: state => state.agentForm,
  formInstance: state => state.formInstance,
  recordingToken: state => state.recordingToken,
  mandatoryTags: state => state.mandatoryTags,
  inboundData: state => state.inboundData,
  callData: state => state.callData,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
