import UAParser from 'ua-parser-js';

const parser = new UAParser(navigator.userAgent || navigator.vendor || window.opera);

class NotificationAPI {

  constructor(events = {}) {
    this.events = Object.assign({
      denied: () => {},
    }, events);
    this.mobile = this.isMobile();
  }

  // eslint-disable-next-line class-methods-use-this
  isMobile() {
    const device = parser.getDevice();
    return ['mobile', 'tablet', 'smarttv', 'wearable'].includes(device.type);
  }

  // eslint-disable-next-line class-methods-use-this
  compatibility() {
    return 'Notification' in window && !this.isMobile();
  }

  // eslint-disable-next-line class-methods-use-this
  async permissions() {
    if (!this.compatibility()) return;
    const have = window.Notification.permission === 'granted';
    // eslint-disable-next-line consistent-return
    return have || (await window.Notification.requestPermission() === 'granted');
  }

  // eslint-disable-next-line class-methods-use-this,consistent-return
  send(title, message, interaction = true) {
    if (this.compatibility() && document.visibilityState === 'hidden') {
      const notification = new window.Notification(title, {
        body: message,
        icon: document.querySelector('link[rel="icon"]').getAttribute('href'),
        requireInteraction: interaction,
        vibrate: [200, 100, 200]
      });
      notification.onclick = () => window.focus();
    }
  }

}

export default NotificationAPI;
