import WebAuth from '@videsk/jwt-webauth';
import axios from './axios';

const options = { delay: 3 };

const auth = new WebAuth(options);

auth.on('verify', accessToken => axios.get('/auth/check-token', { headers: { Authorization: `Bearer ${accessToken}` } }));

auth.on('renew', async (refreshToken) => {
  const response = await axios.post('/auth/refresh-token', { refreshToken });
  return response.data.accessToken;
});

auth.on('renewed', () => {
  axios.defaults.headers.common.Authorization = `Bearer ${auth.getTokens('accessToken')}`;
});

async function requestInterceptor(config) {
  const whitelist = ['/auth/logout', '/auth/login', '/auth', '/auth/password-reset'];
  if (config && 'headers' in config && !whitelist.includes(config.url)) config.headers.Authorization = `Bearer ${auth.getTokens('accessToken')}`;
  return config;
}

function errorInterceptor(error) {
  return Promise.reject(error);
}

axios.interceptors.request.use(requestInterceptor, errorInterceptor);

export default auth;
