import Vue from 'vue';
import {
  Select, Input, Icon, Tooltip, Popover, Button, Checkbox, Modal, Divider, Drawer, Card, Table, Tabs, Tag, Alert, Radio, Switch, message, DatePicker, TimePicker, Row, Col, notification, Slider, Badge, Menu, Dropdown, Popconfirm, Collapse, Timeline
} from 'ant-design-vue';

Vue.use(Select);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Tooltip);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Modal);
Vue.use(Divider);
Vue.use(Drawer);
Vue.use(Card);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(Alert);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Row);
Vue.use(Col);
Vue.use(Slider);
Vue.use(Badge);
Vue.use(Menu);
Vue.use(Dropdown);
Vue.use(Popconfirm);
Vue.use(Collapse);
Vue.use(Timeline);

Vue.prototype.$notification = notification;
Vue.prototype.$message = message;
