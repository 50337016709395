export default {
  en: {
    logs: 'Registros',
    documentLog: 'Estado documento',
    fingerprintLog: 'Estado huella',
    deviceStatus: 'Devices status',
  },
  es: {
    logs: 'Registros',
    documentLog: 'Estado documento',
    fingerprintLog: 'Estado huella',
    deviceStatus: 'Estado de dispositivos',
  },
  fr: {
    logs: 'Registros',
    documentLog: 'Estado documento',
    fingerprintLog: 'Estado huella',
    deviceStatus: 'État de l\'appareil',
  },
}
