export default {
  en: {
    id: 'ID',
    loading: 'Loading',
    save: 'Save',
    publish: 'Publish',
    yes: 'Yes',
    no: 'No',
    view: 'View',
    ok: 'Ok',
    cancel: 'Cancel',
    edit: 'Edit {entity}',
    remove: 'Delete {entity}',
    confirm: 'Confirm',
    reports: 'See reports',
    suspend: 'Suspend',
    reload: 'Update',
    reset: 'Reset',
    filter: 'Filter',
    name: 'Name',
    empty: 'No data found. { extra }',
    create: 'Create {entity}',
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    message: 'Message',
    privilege: 'Privilege',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    default: 'Default',
    custom: 'Custom',
    start: 'Start',
    end: 'End',
    today: 'Today',
    second: 'Second | Seconds',
    minute: 'Minute | Minutes',
    hour: 'Hour | Hours',
    month: 'Month | Months',
    day: 'Day | Days',
    date: 'Date',
    install: 'Install',
    owner: 'Owner',
    copy: 'Copy',
    ip: 'IP Address',
    users: 'Users',
    secret: 'Secret',
    log: 'Log',
    description: 'Description',
    label: 'Label',
    duration: 'Duration',
    daysAndHours: 'Days of week',
    priority: 'Priority',
    referrer: 'Website',
    notBefore: 'Not before of',
    optional: 'Optional',
    generate: 'Generates',
    close: 'Close',
    activate: 'Activate',
    blocked: 'Blocked',
    externalSync: 'Synced event',
    data: 'Data | Data',
    timezone: 'Timezone',
    segment: 'Segment | Segments',
    branch: 'Branch | Branches',
    ticket: 'Ticket',
    poc: 'Point of contact',
    deleting: {
      type: 'Please type in the {word} of the {entity} to confirm. | Please type the number {entity} for confirm the deletion.',
      undone: 'This action cannot be undone.',
      permanently: 'This will permanently delete the {entity} | This will permanently delete the {entity} selected',
      all: 'and all data associated.',
      sure: 'Are you sure you want remove this {entity}?',
      caution: 'Unexpected bad things will happen if you don’t read this!',
    },
    creating: {
      title: 'Create a new {entity}',
    },
    editing: {
      title: 'Edit {entity}',
    },
    deleted: {
      success: {
        title: '{entity} deleted | {entity} deleted',
        description: 'The {entity} {name} was deleted. | The {name} {entity} was deleted.',
      },
      fail: {
        title: 'Error deleting {entity} | Error deleting {entity}',
        description: 'The {entity} {name} cannot be deleted, please try again. | The {name} {entity} cannot be deleted, please try again.',
      },
    },
    langs: {
      es: 'Spanish',
      en: 'English',
    },
    copied: {
      error: 'The code cannot be copied, please try manually',
      successful: 'The code was copied successful',
    },
    status: {
      online: 'Connected',
      occupied: 'In call',
      offline: 'Disconnected',
    },
  },
  es: {
    id: 'ID',
    loading: 'Cargando',
    save: 'Guardar',
    publish: 'Publicar',
    yes: 'Si',
    no: 'No',
    view: 'Ver',
    ok: 'Aceptar',
    cancel: 'Cancelar',
    edit: 'Editar {entity}',
    remove: 'Eliminar {entity}',
    confirm: 'Confirmar',
    reports: 'Ver reportes',
    suspend: 'Suspender',
    reload: 'Actualizar',
    reset: 'Limpiar',
    filter: 'Filtrar',
    name: 'Nombre',
    empty: 'No se han encontrado datos. { extra }',
    create: 'Crear {entity}',
    firstname: 'Nombre',
    lastname: 'Apellido',
    email: 'Correo electrónico',
    message: 'Mensaje',
    privilege: 'Privilegio',
    password: 'Contraseña',
    passwordConfirm: 'Confirme contraseña',
    default: 'Predeterminado',
    custom: 'Personalizado',
    start: 'Inicio',
    end: 'Fin',
    today: 'Hoy',
    second: 'segundo | segundos',
    minute: 'minuto | minutos',
    hour: 'hora | horas',
    month: 'Mes | Meses',
    day: 'Día | Días',
    date: 'Fecha',
    install: 'Instalar',
    owner: 'Propietario',
    copy: 'Copiar',
    ip: 'Dirección IP',
    users: 'Usuarios',
    secret: 'Secreto',
    log: 'Registro',
    description: 'Descripción',
    label: 'Nombre',
    duration: 'Duración',
    daysAndHours: 'Días de la semana',
    priority: 'Prioridad',
    referrer: 'Sitio web',
    notBefore: 'No antes de',
    optional: 'Opcional',
    generate: 'Generar',
    close: 'Cerrar',
    activate: 'Activar',
    blocked: 'Blocked',
    externalSync: 'Evento sincronizado',
    data: 'Dato | Datos',
    timezone: 'Zona horaria',
    segment: 'Segmento | Segmentos',
    branch: 'Oficina | Oficinas',
    ticket: 'Ticket',
    poc: 'Punto de contacto',
    deleting: {
      type: 'Escriba el {word} del {entity} para confirmar. | Escriba el número {entity} para confirmar la eliminación.',
      undone: 'Esta acción no se puede deshacer.',
      permanently: 'Esto eliminará permanentemente el {entity} | Esto eliminará permanentemente los {entity} seleccionados',
      all: 'y todos los datos asociados.',
      sure: 'Está seguro que desea eliminar este {entity}? | Está seguro que desea eliminar estos {entity}?',
      caution: '¡Cosas malas e inesperadas sucederán si no lee esto!',
    },
    creating: {
      title: 'Crear un nuevo {entity}',
    },
    editing: {
      title: 'Editar {entity}',
    },
    deleted: {
      success: {
        title: 'El {entity} fue eliminado | Los {entity} fueron eliminados',
        description: 'El {entity} {name} fue eliminado. | Los {name} {entity} fueron eliminados.',
      },
      fail: {
        title: 'Error eliminando el {entity} | Error eliminando los {entity}',
        description: 'No se ha podido eliminar el {entity} {name}, intente nuevamente. | No se ha podido eliminar {name} {entity}, intente nuevamente.',
      },
    },
    langs: {
      es: 'Español',
      en: 'Inglés',
    },
    copied: {
      error: 'El código no se puede copiar, intente manualmente',
      successful: 'El código fue copiado exitosamente',
    },
    status: {
      online: 'Conectado',
      occupied: 'En llamada',
      offline: 'Desconectado',
    },
  },
  fr: {
    id: 'ID',
    loading: 'Chargement',
    save: 'Sauvegarder',
    publish: 'Publier',
    yes: 'Oui',
    no: 'Non',
    view: 'Voir',
    ok: 'OK',
    cancel: 'Annuler',
    edit: 'Modifier {entity}',
    remove: 'Supprimer {entity}',
    confirm: 'Confirmer',
    reports: 'Voir les rapports',
    suspend: 'Suspendre',
    reload: 'Recharger',
    reset: 'Réinitialiser',
    filter: 'Filtrer',
    name: 'Nom',
    empty: 'Aucune donnée trouvée. { extra }',
    create: 'Créer {entity}',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    email: 'Email',
    message: 'Message',
    privilege: 'Privilège',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le mot de passe',
    default: 'Par défaut',
    custom: 'Personnalisé',
    start: 'Début',
    end: 'Fin',
    today: 'Aujourd\'hui',
    second: 'seconde | secondes',
    minute: 'minute | minutes',
    hour: 'heure | heures',
    month: 'Mois | Mois',
    day: 'Jour | Jours',
    date: 'Date',
    install: 'Installer',
    owner: 'Propriétaire',
    copy: 'Copier',
    ip: 'Adresse IP',
    users: 'Utilisateurs',
    secret: 'Secret',
    log: 'Journal',
    description: 'Description',
    label: 'Nom',
    duration: 'Durée',
    daysAndHours: 'Jours de la semaine',
    priority: 'Priorité',
    referrer: 'Site web',
    notBefore: 'Pas avant',
    optional: 'Optionnel',
    generate: 'Générer',
    close: 'Fermer',
    activate: 'Activer',
    blocked: 'Bloqué',
    externalSync: 'Événement synchronisé',
    data: 'Donnée | Données',
    timezone: 'Fuseau Horaire',
    segment: 'Segment | Segments',
    branch: 'Agence | Agences',
    ticket: 'Ticket',
    poc: 'Point de contact',
    deleting: {
      type: 'Tapez le {word} du {entity} pour confirmer. | Tapez le numéro {entity} pour confirmer la suppression.',
      undone: 'Cette action ne peut pas être annulée.',
      permanently: 'Cela supprimera définitivement le {entity} | Cela supprimera définitivement les {entity} sélectionnés',
      all: 'et toutes les données associées.',
      sure: 'Êtes-vous sûr de vouloir supprimer ce {entity}? | Êtes-vous sûr de vouloir supprimer ces {entity}?',
      caution: 'Des choses mauvaises et inattendues se produiront si vous ne lisez pas cela!',
    },
    creating: {
      title: 'Créer un nouveau {entity}',
    },
    editing: {
      title: 'Modifier {entity}',
    },
    deleted: {
      success: {
        title: 'Le {entity} a été supprimé | Les {entity} ont été supprimés',
        description: 'Le {entity} {name} a été supprimé. | Les {name} {entity} ont été supprimés.',
      },
      fail: {
        title: 'Erreur lors de la suppression du {entity} | Erreur lors de la suppression des {entity}',
        description: 'Impossible de supprimer le {entity} {name}, veuillez réessayer. | Impossible de supprimer {name} {entity}, veuillez réessayer.',
      },
    },
    langs: {
      es: 'Espagnol',
      en: 'Anglais',
    },
    copied: {
      error: 'Le code ne peut pas être copié, essayez manuellement',
      successful: 'Le code a été copié avec succès',
    },
    status: {
      online: 'En ligne',
      occupied: 'En appel',
      offline: 'Déconnecté',
    },
}

};
