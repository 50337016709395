
class LocalRecorder extends EventTarget {

  constructor(options = {}) {
    super();
    this.context = new AudioContext();
    this.destination = this.context.createMediaStreamDestination();
    this.streams = new Map();
    this.options = options;
    this.chunks = [];
    this.context.addEventListener('statechange', () => (this.context.state === 'suspended') && this.context.resume());
  }

  get started() {
    return !!this.recorder;
  }

  get recording() {
    return this.recorder && this.recorder.state === 'recording';
  }

  toggle() {
    if (!this.recorder) return this.start();
    if (this.recording) return this.pause();
    return this.resume();
  }

  attachPeer(peerConnection) {
    if (!(peerConnection instanceof RTCPeerConnection)) return;
    peerConnection.remoteStreams.forEach(stream => this.attachFromPeer(peerConnection, stream));
    peerConnection.addEventListener('track', event => {
      const [stream]= event.streams;
      this.attachFromPeer(peerConnection, stream);
    });
    peerConnection.addEventListener('stream:added', (event) => {
      this.attachFromPeer(peerConnection, event.detail.stream);
    });
  }

  attachFromPeer(peerConnection, stream) {
    if (!this.constructor.checkAudioTrack(stream)) return console.warn('Stream doesn\'t have audio track', stream, peerConnection);
    if (this.streams.has(stream.id) && this.streams.get(stream.id).has(peerConnection.id)) return;
    if (!this.streams.has(stream.id)) this.streams.set(stream.id, new Set([stream]));
    else this.streams.get(stream.id).add(peerConnection.id);
    this.attach(stream);
  }

  attach(stream) {
    if (!this.constructor.verifyMediaStream(stream) || !this.constructor.checkAudioTrack(stream)) return this;
    const source = this.context.createMediaStreamSource(stream);
    source.connect(this.destination);
    return this;
  }

  async start(constraints = {}) {
    if (this.constructor.verifyMediaStream(this.stream)) return this.stop();
    this.stream = await this.screen(constraints);
    this.stream.getVideoTracks()[0].addEventListener('ended', () => this.stop());
    this.options.mimeType = ('mimeType' in this.options) ? this.options.mimeType : this.constructor.getMimeType(this.stream);
    this.input = this.constructor.getSingleMediaStream(this.stream, this.destination.stream);
    this.recorder = new MediaRecorder(this.input, this.options);
    this.recorder.addEventListener('dataavailable', event => event.data?.size > 0 && this.chunks.push(event.data));
    this.recorder.addEventListener('start', () => this.dispatchEvent('recording'));
    this.recorder.addEventListener('pause', () => this.dispatchEvent('paused'));
    this.recorder.addEventListener('resume', () => this.dispatchEvent('recording'));
    this.recorder.addEventListener('stop', () => this.stop());
    this.recorder.addEventListener('error', console.error);
    this.recorder.start();
  }

  async stop() {
    if (!this.stream || !this.recorder) return;
    this.stream.getTracks().forEach(track => track.stop());
    this.stream = undefined;
    if (this.recorder.state !== 'inactive') this.recorder.stop();
    this.recorder = undefined;
    await new Promise(resolve => setTimeout(resolve, 500));
    this.dispatchEvent('stop');
  }

  resume() {
    if (this.recorder && this.recorder.state === 'paused') this.recorder.resume();
  }

  pause() {
    if (this.recorder && this.recorder.state === 'recording') this.recorder.pause();
  }

  async screen(constraints = {}) {
    const stream = await navigator.mediaDevices.getDisplayMedia(Object.assign({ video: true, preferCurrentTab: true }, constraints)).catch(e => e);
    if (stream instanceof Error) return this.screen(constraints);
    return stream;
  }

  download(filename = new Date().getTime()) {
    const blob = new Blob(this.chunks, { type: this.options.mimeType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style = 'display: none';
    document.body.appendChild(a);
    a.href = url;
    const extension = this.options.mimeType.split('/')[1];
    a.download = `${filename}.${extension}`;
    a.click();
    window.URL.revokeObjectURL(url);
    const canceled = !this.dispatchEvent('clean');
    if (canceled) this.chunks = [];
    return this;
  }

  dispatchEvent(eventName = '', options = {}) {
    const event = new CustomEvent(eventName, Object.assign({ composed: true, cancelable: true }, options));
    return super.dispatchEvent(event);
  }

  streamOfPeerIsRecording(peerConnection) {
    return [...this.streams].some(streamId => this.streams.get(streamId).has(peerConnection.id));
  }

  static getSingleMediaStream(videoMediaStream, audioMediaStream) {
    const [audioTrack, videoTrack] = [audioMediaStream.getAudioTracks()[0], videoMediaStream.getVideoTracks()[0]];
    return new MediaStream([audioTrack, videoTrack]);
  }

  static checkAudioTrack(stream) {
    if (!stream) return;
    const [track] = stream.getAudioTracks() || [];
    return track?.readyState === 'live';
  }

  static verifyMediaStream(stream) {
    return stream instanceof MediaStream && stream.active;
  }

  static getMimeType(stream) {
    const audioTracks = stream.getAudioTracks().length > 0;
    const videoTracks = stream.getVideoTracks().length > 0;
    if ((audioTracks && videoTracks) || (!audioTracks && videoTracks)) return 'video/webm';
    else if (audioTracks) return 'audio/webm';
    return '';
  }

}

export default LocalRecorder;
