export default {
    en: {
        errors: {
            segments: 'Error getting segments',
            personalLink: 'Error trying to generate the personal link',
        },
    },
    es: {
        errors: {
            segments: 'Error obteniendo los segmentos',
            personalLink: 'Ha habido un error generando el link personal',
        },
    },
  fr: {
    errors: {
        segments: 'Erreur lors de l\'obtention des segments',
        personalLink: 'Une erreur est survenue lors de la génération du lien personnel',
    },
}

}
