<template>
  <div style="width: 100%; height: 100%;">
    <div class="w-full p-10" style="text-align: center;" v-show="!connected">
      <h1 class="title" style="margin-bottom: 10px; line-height: 1;">{{ $t('call.beamport.transfer.title') }}</h1>
      <p class="subtitle">{{ $t('call.beamport.transfer.subtitle') }}</p>
      <a-button type="primary" style="margin-top: 30px;" :loading="connecting" @click="connect">{{ (connecting) ? $t('call.beamport.connecting') : $t('call.beamport.connect') }}</a-button>
    </div>
    <videsk-fileshare style="width: 100%; height: 100%;" id="files-transfer" v-show="connected"></videsk-fileshare>
  </div>
</template>

<script>
import BeamPort from '@videsk/beamport';
import '@videsk/fileshare-component';
import FileUpload from '@videsk/upload-chunk';

export default {
  name: 'fileshare',
  data: () => ({
    connecting: false,
    beamport: null,
    connected: false,
  }),
  computed: {
    socket() {
      return this.$store.getters['global/socket'];
    },
    isMeeting() {
      return this.$store.getters['calendar/onMeeting'];
    },
    identifier() {
      return this.$store.getters['call/id'];
    },
  },
  watch: {
    connected() {
      this.connecting = false;
    },
  },
  mounted() {
    this.component = document.querySelector('videsk-fileshare#files-transfer');
    document.addEventListener('customer:disconnect', () => {
        if (this.component) this.component.disabled = true;
    }, { once: true });
    document.addEventListener('beamport:files:connect:files', event => {
      this.setupBeamPort(event.detail);
    }, { once: true });

    this.component.addEventListener('files', event => {
      if (!this.beamport?.connected) return;
      const elements = event.detail;
      elements.forEach(element => this.beamport?.send(element.file));
    });
    this.component.addEventListener('cancel', event => {
      this.beamport.cancel(event.detail.file);
    });
    this.component.addEventListener('delete', event => {
      this.beamport.cancel(event.detail.file);
    });
    this.component.addEventListener('download', event => {
      const { id } = event.detail;
      const element = this.component.get(id);
      element?.download();
    });
    this.component.addEventListener('preview', event => {
      const { file } = event.detail;
      document.querySelector('videsk-fileshare-viewer').data = file;
    });
    this.component.addEventListener('save', async event => {
      const { file, id } = event.detail;
      const element = this.component.get(id);
      element.state = 'saving';
      const accessToken = await this.getUploadToken(file);
      if (!accessToken) return;
      const uploader = new FileUpload(file, accessToken, this.$fileOptions.http);
      uploader.addEventListener('progress', event => {
        element.percentage = event.detail;
      });
      uploader.upload().catch(() => {
        this.$notification.error({ description: this.$t('call.saveFiles.errorSaving') });
        element.state = 'received';
      });
    });
  },
  methods: {
    async connect() {
      this.connecting = true;
      this.socket.emit('beamport:open', { channel: 'files' });
    },
    setupBeamPort(event) {
        const { accessToken, channel } = event;

        if (channel !== 'files') return;

        this.beamport = new BeamPort({ websocket: this.$signalingOptions.websocket });
        this.beamport.connect(accessToken);
        this.beamport.addEventListener('description', event => {
            const beamPortFile = event.detail;
            const file = this.component.create(beamPortFile.data, beamPortFile.filename, { size: beamPortFile.size, type: beamPortFile.mimeType });
            const element = this.component.add(file, beamPortFile.crc32, 'receiving', true);
            element.upload = true;
        });
        this.beamport.addEventListener('progress', event => {
            const { percentage, crc32 } = event.detail;
            const element = this.component.get(crc32);
            if (element) element.percentage = percentage;
        });
        this.beamport.addEventListener('canceled', event => {
          const { crc32 } = event.detail;
          const element = this.component.get(crc32);
          if (element) element.state = 'deleted';
        });
        this.beamport.addEventListener('disconnected', () => {
          this.component.disabled = !this.beamport.connected;
          this.connected = false;
        });
        this.beamport.addEventListener('connected', () => {
          this.component.disabled = !this.beamport.connected;
          this.connected = true;
        });
        this.beamport.addEventListener('ports', () => {
          this.component.disabled = !this.beamport.connected;
        });
        if (this.component) this.component.disabled = false;
        this.connecting = false;
    },
    async getUploadToken(file) {
      const FileItem = customElements.get('videsk-file-item');
      const { type, name, size } = file;
      const arrayBuffer = await file.arrayBuffer();
      const crc32 = FileUpload.calculateCRC32(arrayBuffer);
      const identifierEntity = this.isMeeting ? 'appointment': 'call';
      const payload = { size, crc32, extension: FileItem.getFileExtension(name), type: type.split('/')[1] || 'image' };
      payload[identifierEntity] = this.identifier;
      const response = await this.$http.post('/files/authorization', payload).catch(e => e);
      if (!(response instanceof Error)) return response.data.accessToken;
      this.$notification.error({ message: this.$t('call.saveFiles.noAccess') });
    }
  },
};
</script>

<style>
.w-full {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-10 {
  padding: 1em;
}

.manager-files-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-select-selection__rendered {
  display: flex;
  align-items: center;
}
</style>
