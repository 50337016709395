export default {
  en: {
    home: 'Home',
    utilities: 'Utilities',
    recordings: 'Recordings',
    profile: 'Profile',
    statuses: {
      available: 'Available',
        occupied: 'In ACD',
        meeting: 'In Meeting',
        'in-call': 'In call',
        busy: 'Occupied',
        break: 'Break',
        bath: 'Bath',
        meal: 'Meal',
        gathering: 'Gathering',
        training: 'Training',
        disconnected: 'Disconnected',
    }
  },
  es: {
    home: 'Inicio',
    utilities: 'Utilidades',
    recordings: 'Grabaciones',
    profile: 'Perfil',
    statuses: {
      available: 'Disponible',
        occupied: 'En ACD',
        meeting: 'En reunión',
        'in-call': 'En llamada',
        busy: 'Ocupado',
        break: 'Descanso',
        bath: 'Baño',
        meal: 'Comida',
        gathering: 'Reunión',
        training: 'Capacitación',
        disconnected: 'Desconectado',
    }
  },
  fr: {
    home: 'Accueil',
    utilities: 'Utilitaires',
    recordings: 'Enregistrements',
    profile: 'Profil',
    statuses: {
      available: 'Disponible',
      occupied: 'En ACD',
      meeting: 'En réunion',
      'in-call': 'En appel',
      busy: 'Occupé',
      break: 'Pause',
      bath: 'Salle de bain',
      meal: 'Repas',
      gathering: 'Réunion',
      training: 'Formation',
      disconnected: 'Déconnecté',
    }
}

}
