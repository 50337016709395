import axios from 'axios';

const endpoint = '/recordings';
const entity = 'recording';
const key = 'id';

const state = {
  data: {},
};

const mutations = {
  data(state, value) {
    state.data = value;
  },
};

const actions = {
  // eslint-disable-next-line consistent-return
  async get({ commit }, params = '') {
    const response = await axios.get(`${endpoint}${params}`).catch(e => e);
    if (response instanceof Error) return response;
    commit('data', response.data);
  },
  find({}, options) {
    const { id, params = '' } = options;
    const base = `${endpoint}/${id}`;
    const url = (params) ? `${base}?${params}` : base;
    return new Promise((resolve, reject) => {
      axios.get(url)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  },
  post({ dispatch }, body = {}) {
    return new Promise((resolve, reject) => {
      axios.post(`${endpoint}`, body)
        .then(({ data }) => {
          dispatch('get')
            .then(() => resolve({
              creating: true, entity, key, data
            }))
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    });
  },
  patch({ dispatch }, { id, body = {} }) {
    return new Promise((resolve, reject) => {
      if (!id) reject();
      axios.patch(`${endpoint}/${id}`, body)
        .then(({ data }) => {
          dispatch('get')
            .then(() => resolve({
              creating: false, entity, key, data
            }))
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    });
  },
  delete({ dispatch }, id = '') {
    return new Promise((resolve, reject) => {
      axios.delete(`${endpoint}/${id}`)
        .then(({ data }) => {
          dispatch('get')
            .then(() => resolve({ entity, key, data }))
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    });
  },
  deletes({ dispatch }, array) {
    return new Promise((resolve, reject) => {
      array.map(async (id, index) => {
        await dispatch('delete', id).catch(reject);
        if (array.length - 1 === index) dispatch('get').then(() => resolve()).catch(() => reject());
      });
    });
  },
};

const getters = {
  data: state => state.data,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
