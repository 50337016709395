<template>
  <div class="appointment-container">
    <div class="appointment-actions">
      <a-button @click="closeDrawer">
        {{ $t('close') }}
      </a-button>
      <a-button
        type="primary"
        style="margin-left: 10px;"
        v-if="!rescheduled && !canceled && !hideActions && !onProcess"
        @click="saveChanges"
      >
        {{ $t('save') }}
      </a-button>
    </div>
    <h6>{{ $t('summary') }}</h6>
    <h1 class="appointment-title">
      {{ title }}
      <div style="margin-top: 5px;">
        <a-tag
          type="rounded"
          v-show="!rescheduled && !canceled"
        >
          {{ $t(`calendar.types.${type}`) }}
        </a-tag>
        <a-tag
          type="rounded"
          v-show="status"
        >
          {{ $t(`calendar.status.${status}`) }}
        </a-tag>
      </div>
    </h1>
    <h6>{{ $t('date') }}</h6>
    <div class="appointments-date-inputs">
      <div>
        <a-date-picker
          format="DD-MM-YYYY"
          :allow-clear="false"
          style="max-width: 80%; margin-right: 1rem;"
          :locale="languageDatePicker"
          :input-read-only="hideActions || onProcess"
          :value="$moment(startAt)"
          :show-today="false"
          @change="startAtChange"
          :disabled="true"
        />
        <a-time-picker
          :allow-clear="false"
          format="HH:mm"
          :placeholder="$t('hour')"
          :input-read-only="hideActions || onProcess"
          :value="$moment(startAt)"
          @change="startAtChange"
          :disabled="true"
        />
      </div>
      <a-icon
        type="arrow-down"
        style="padding: 1rem 0;"
      />
      <div>
        <a-date-picker
          format="DD-MM-YYYY"
          :allow-clear="false"
          style="max-width: 80%; margin-right: 1rem;"
          :locale="languageDatePicker"
          :input-read-only="hideActions || onProcess"
          :value="$moment(endAt)"
          :show-today="false"
          @change="endAtChange"
          :disabled="true"
        />
        <a-time-picker
          :allow-clear="false"
          format="HH:mm"
          :placeholder="$t('hour')"
          :input-read-only="hideActions || onProcess"
          :value="$moment(endAt)"
          @change="endAtChange"
          :disabled="true"
        />
      </div>
    </div>
    <div class="appointment-data">
      <a-divider
        v-if="!blocked && canAccess"
        :dashed="true"
      />
      <a-button
        v-if="!blocked && canAccess"
        type="primary"
        style="padding: 10px 20px; height : auto;"
        @click="joinMeeting"
      >
        <a-icon
          type="video-camera"
          theme="filled"
          style="margin-right: 5px;"
        /> {{ $t('meeting.join') }}
      </a-button>
      <a-divider v-if="!blocked && canAccess" :dashed="true" />
      <div
        class="appointment-details-container"
        v-if="!blocked"
      >
        <a-tabs default-active-key="1" @tabClick="rescheduleMeeting">
          <a-tab-pane
            key="1"
            tab="Detalles"
            force-render
          >
            <div
              class="appointment-extra-data"
              v-if="rescheduled"
            >
              <h3 style="user-select: none;">
                {{ $t('meeting.newMeeting') }}
              </h3>
              <a-button @click="getRescheduled">
                <a-icon
                  type="calendar"
                  style="margin-right: 5px;"
                /> {{ $t('meeting.checkNewMeeting') }}
              </a-button>
            </div>
            <div
              style="padding: 1rem 0 1.5rem 0;"
              v-if="canceled"
            >
              <div class="appointment-extra-data">
                <h3 style="user-select: none; color: #af1717;">
                  {{ $t('meeting.canceledBy') }}
                </h3>
                <p>{{ $t(`calendar.canceledBy.${canceledBy}`) }}</p>
              </div>
              <div class="appointment-extra-data">
                <h3 style="user-select: none; color: #af1717;">
                  {{ $t('meeting.canceledAt') }}
                </h3>
                <p>{{ new Intl.DateTimeFormat(language, { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }).format(new Date(canceledAt)) }}</p>
              </div>
              <div class="appointment-extra-data">
                <h3 style="user-select: none; color: #af1717;">
                  {{ $t('meeting.canceledReason') }}
                </h3>
                <p>{{ cancelReason || '-' }}</p>
              </div>
            </div>
            <form-view
              v-if="form"
              :form="form"
            />
            <a-divider :dashed="true" />
            <div class="appointment-extra-data-container">
              <div class="appointment-extra-data">
                <h3>
                  {{ $t('meeting.accessUrl') }}
                </h3>
                <div class="url-copy">
                  <p class="limit-url truncate">{{ joinUrl || '-' }}</p>
                  <a-icon type="copy" @click="copyToClipboard(joinUrl)" />
                </div>
              </div>
              <div class="appointment-extra-data">
                <h3>
                  {{ $t('meeting.modifyUrl') }}
                </h3>
                <div class="url-copy">
                  <p class="limit-url truncate">{{ modifyUrl || '-' }}</p>
                  <a-icon type="copy" @click="copyToClipboard(modifyUrl)" />
                </div>
              </div>
              <a-divider :dashed="true" />
              <div class="appointment-extra-data">
                <h3 style="user-select: none;">
                  {{ $t('timezone') }}
                </h3>
                <p>{{ timezone }}</p>
              </div>
              <div class="appointment-extra-data">
                <h3 style="user-select: none;">
                  {{ $t('meeting.scheduledSince') }}
                </h3>
                <p>{{ referrer || '-' }}</p>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane
            v-if="!hideActions && !onProcess"
            key="3"
            :tab="$t('meeting.rescheduleMeeting')"
            force-render
          ></a-tab-pane>
          <a-tab-pane
            v-if="!hideActions && !onProcess"
            key="2"
            :tab="$t('meeting.cancelMeeting')"
            force-render
          >
            <div class="appointment-extra-data">
              <h3 style="user-select: none;">
                {{ $t('meeting.canceledReason') }}
              </h3>
              <a-textarea
                :placeholder="$t('meeting.canceledReasonPlaceholder')"
                v-model="reasonCancelation"
              />
            </div>
            <a-button
              style="width: 100%;"
              type="danger"
              @click="cancelAppointment"
            >
              {{ $t('meeting.cancelMeeting') }}
            </a-button>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/es_ES';
import FormView from './form.vue';

export default {
  components: {
    FormView,
  },
  data: () => ({
    reasonCancelation: ''
  }),
  computed: {
    languageDatePicker() {
      return locale;
    },
    language() {
      return window.navigator.language;
    },
    appointment() {
      return this.$store.getters['calendar/appointmentData'];
    },
    type() {
      return this.appointment.type;
    },
    status() {
      return this.appointment.status;
    },
    startAt() {
      return this.appointment.startAt;
    },
    endAt() {
      return this.appointment.endAt;
    },
    joinUrl() {
      return this.appointment.joinUrl;
    },
    modifyUrl() {
      return this.appointment.modifyUrl;
    },
    blocked() {
      return this.type === 'blocked';
    },
    canceled() {
      return this.status === 'canceled';
    },
    rescheduled() {
      return this.status === 'rescheduled';
    },
    title() {
      const { service = {} } = this.appointment;
      const { title = '' } = service;
      return !this.blocked ? this.$t('meeting.meetingTitle', { name: title.toLowerCase() }) : this.$t('meeting.blockedTitle', { name: title.toLowerCase() });
    },
    form() {
      const { form } = this.appointment;
      return form && form.map(field => {
        const data = { label: field.label, value: field.value };
        if ('options' in field && field.options.length > 0) {
          const { label } = field.options.find(option => option.value === field.value) || {};
          data.value = label || 'Undefined value';
        }
        return data;
      });
    },
    timezone() {
      return this.appointment.timezone;
    },
    referrer() {
      return this.appointment.referrer;
    },
    cancelReason() {
      return this.appointment.cancelReason;
    },
    canceledAt() {
      return this.appointment.canceledAt;
    },
    canceledBy() {
      return this.appointment.canceledBy;
    },
    hideActions() {
      return this.canceled || this.rescheduled || new Date(this.endAt) < new Date();
    },
    onProcess() {
      return new Date(this.startAt) < new Date() && new Date(this.endAt) > new Date();
    },
    canAccess() {
      return !this.canceled || !this.rescheduled || new Date(new Date(this.endAt).setMinutes(new Date(this.endAt).getMinutes() + 30)) > new Date() && !this.$store.getters['calendar/onMeeting'];
    },
  },
  methods: {
    getRescheduled() {
      const socket = this.$store.getters['global/socket'];
      socket.emit('appointments:get', { appointment: this.appointment.rescheduledAppointment });
    },
    cancelAppointment() {
      const { _id } = this.appointment;
      this.$store.dispatch('calendar/cancel', { id: _id, cancelReason: this.reasonCancelation });
      this.closeDrawer();
    },
    startAtChange(event) {
      this.$store.commit('calendar/setAppointmentKey', { key: 'startAt', value: new Date(event._d).toISOString() });
      if (new Date(event._d) > new Date(this.endAt)) this.$store.commit('calendar/setAppointmentKey', { key: 'endAt', value: new Date(new Date(event._d).setMinutes(event._d.getMinutes() + 5)) });
    },
    endAtChange(event) {
      this.$store.commit('calendar/setAppointmentKey', { key: 'endAt', value: new Date(event._d).toISOString() });
      if (new Date(event._d) < new Date(this.startAt)) this.$store.commit('calendar/setAppointmentKey', { key: 'startAt', value: new Date(new Date(event._d).setMinutes(event._d.getMinutes() - 5)) });
    },
    saveChanges() {
      const { _id, startAt, endAt } = this.appointment;
      this.$store.dispatch('calendar/patchAppointment', { id: _id, startAt, endAt });
      this.closeDrawer();
    },
    closeDrawer() {
      this.$store.commit('calendar/openAppointmentDrawer', false);
    },
    joinMeeting() {
      this.$store.dispatch('calendar/joinMeeting', this.appointment);
      this.$emit('join');
    },
    rescheduleMeeting(event) {
      if (event === '3') this.$emit('reschedule', this.appointment);
    },
    copyToClipboard(value) {
      try {
        const el = document.createElement('textarea');
        el.value = value;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$notification.success({ message: this.$t('copyToClipboard') });
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style>
h6 {
  color: gray;
  font-weight: normal;
}

.appointment-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}

.appointment-title {
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.appointments-date-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 0;
}

.appointments-date-inputs > span {
  width: 100%;
  text-align: center;
  display: block;
  padding: 1rem 0;
}

.appointments-date-inputs div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appointment-data {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.appointment-details-container {
  width: 100%;
  padding-top: 1rem;
}

h3 {
  margin-bottom: 0.7rem;
}

.appointment-extra-data {
  margin-bottom: 1.5rem;
}

.appointment-extra-data:last-child {
  margin-bottom: 0;
}

.appointment-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 500px) {
    .appointment-container {
        max-height: 100vh;
        overflow: auto;
    }
}

.url-copy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.limit-url {
  width: 285px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
