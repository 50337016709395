import Vue from 'vue';

const compatible = 'pictureInPictureEnabled' in document;

// eslint-disable-next-line consistent-return
async function exit(videoElement) {
  if (compatible && document.pictureInPictureElement && document.pictureInPictureElement.localName === 'videsk-webrtc') await document.exitPictureInPicture().catch(e => e);
  if (videoElement) return setTimeout(() => videoElement.play(), 10);
}

// eslint-disable-next-line no-unused-vars
async function request(videoElement) {
  if (!compatible) return;
  await videoElement.requestPictureInPicture().catch(e => e);
  window.focus();
}

function setLeaveListener(videoElement) {
  videoElement.addEventListener('leavepictureinpicture', () => setTimeout(() => videoElement.play(), 10));
}

function setWindowLeaveListener(videoElement) {
  window.addEventListener('blur', () => {
    if (!window.isCallActive) return;
    return request(videoElement);
  });
  window.addEventListener('focus', () => {
    if (!window.isCallActive) return;
    return exit(videoElement);
  });
  // eslint-disable-next-line consistent-return
  document.addEventListener('keydown', event => {
    // eslint-disable-next-line no-use-before-define
    if (event.altKey && event.key === 'w') return toggle(videoElement);
  });
}

// eslint-disable-next-line consistent-return
function toggle(videoElement) {
  if (!window.isCallActive) return;
  // eslint-disable-next-line consistent-return
  if (!compatible) return console.warn('Your browser is not compatible with PiP.');
  try {
    // eslint-disable-next-line consistent-return
    if (document.pictureInPictureElement && document.pictureInPictureElement.localName === 'videsk-webrtc') return exit(videoElement);
    // eslint-disable-next-line consistent-return
    return request(videoElement);
  } catch (error) {
    console.error(error);
  }
}

Vue.prototype.$pip = {
  toggle,
  setLeaveListener,
  exit,
  request,
  setWindowLeaveListener,
};

export default Vue.prototype.$pip;
