export default function startPendo(user, account) {

  if (!user && !account || !pendo) return;

  pendo.initialize({
    visitor: {
      id: user._id || user.id,
      email: user.email,
      full_name: `${user.firstname} ${user.lastname}`,
    },

    account: {
      id: account._id || account.id,
      name: account.name,
    }
  });
}
