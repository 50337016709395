
class Timezones {

  static dictionary = Intl.supportedValuesOf('timeZone');

  static get find() {
    return this;
  }

  static extractCity(timeZone) {
    const parts = timeZone.split('/');
    return parts[parts.length - 1].replace(/_/g, ' ');
  }

  static city(cityName) {
    const normalizedCityName = cityName.toLowerCase().replace(/\s+/g, '\\s+');
    const cityRegex = new RegExp(normalizedCityName, 'ig');
    return this.dictionary.filter(tz => {
      const extractedCity = this.extractCity(tz);
      return cityRegex.test(extractedCity);
    });
  }

  static exact(query) {
    const normalizedQuery = query.toLowerCase();
    return this.dictionary.filter(tz =>
      tz.toLowerCase() === normalizedQuery ||
      this.extractCity(tz).toLowerCase() === normalizedQuery
    );
  }

  static get cities() {
    return this.dictionary.map(tz => this.extractCity(tz));
  }

}

module.exports = Timezones;
