class SocketWorker extends EventTarget {
  constructor(workerPath = "") {
    super();
    this.worker = new Worker(workerPath);
    this.worker.addEventListener("message", (event) =>
      this.dispatchEvent(...event.data)
    );
    this.events = {};
    this.listenAny = () => {};
  }

  connect(options = {}) {
    this.worker.postMessage({ event: "connect", data: options });
  }

  emit(event, data) {
    this.worker.postMessage({ event: "emit", data: { event, data } });
  }

  any(callback) {
    this.listenAny = callback;
  }

  on(eventName = "", callback = () => {}) {
    this.events[eventName] = callback;
  }

  disconnect() {
    this.worker.postMessage({ event: 'disconnect' });
  }

  dispatchEvent(eventName, args) {
    if (eventName in this.events) this.events[eventName](...args);
    this.listenAny(eventName, ...args);
    const customEvent = new CustomEvent(eventName, { detail: args });
    super.dispatchEvent(customEvent);
  }
}

export default SocketWorker;
