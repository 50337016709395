<template>
  <a-modal
    :visible="show"
    :closable="true"
    :mask-closable="false"
    :centered="true"
    :cancel-text="$t('cancel')"
    :ok-text="transferButtonText"
    @cancel="cancel"
    @ok="confirm"
    :ok-button-props="{ props: { disabled: disabledTransferButton, loading } }"
    :cancel-button-props="{ props: { disabled: loading } }"
    class="modal-max-width"
  >
    <template slot="title">
      <h3 style="font-size: 1em; margin-bottom: 5px;">
        {{ $t('call.transfer.title') }}
      </h3>
      <p style="font-size: 0.9em; color: gray;">
        {{ $t('call.transfer.subtitle') }}
      </p>
    </template>
    <a-tabs
      default-active-key="1"
      @change="changeTransferEntity"
    >
      <a-tab-pane
        key="1"
        class="capitalize"
        :tab="capitalize($tc('entities.department', 2))"
      >
        <template-table
          ref="segments-table"
          entity="segments"
          :columns="departmentsTableColumns"
          :select="transfer"
        >
          <template
            slot="action"
            slot-scope="id"
          >
            <a-button @click="transfer(id)">
              {{ $t('select') }}
            </a-button>
          </template>
        </template-table>
      </a-tab-pane>

      <a-tab-pane
        key="2"
        class="capitalize"
        :tab="capitalize($tc('entities.agent', 2))"
      >
        <template-table
          ref="agents-table"
          entity="agents"
          :columns="agentsTableColumns"
          :select="transfer"
        />
      </a-tab-pane>
      <a-button
        @click="refresh"
        :loading="loadingRefresh"
        slot="tabBarExtraContent"
      >
        {{ $t('refresh') }}
      </a-button>
    </a-tabs>
    <a-alert
      v-show="!disabledTransferButton"
      :message="titleTransfer"
      :description="$t('call.transfer.transfer_to_subtitle')"
      type="info"
      show-icon
    />
  </a-modal>
</template>

<script>
import templateTable from '@/components/call/tables/table.vue';
import departmentsColumns from './tables/columns-departments';
import agentsColumns from './tables/columns-agents';

export default {
  components: {
    templateTable,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    confirm: {
      type: Function,
      required: true,
    },
    cancel: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    selection: {
      segment: null,
      agent: null,
    },
    disabledRefresh: false,
    loadingRefresh: false,
    selectedEntity: 'segments',
  }),
  computed: {
    transferButtonText() {
      return (!this.loading) ? this.$t('call.menu.transfer') : this.$t('call.transfer.transferring');
    },
    disabledTransferButton() {
      const { agent, segment } = this.selection;
      return !agent && !segment;
    },
    nameSelected() {
      if (this.selection.segment || this.selection.agent) {
        const { segment } = this.$store.getters['call/transfer'];
        const entityGlobal = (segment) ? 'segments' : 'agents';
        const entity = (segment) ? 'segment' : 'agent';
        const { data } = this.$store.getters[`global/${entityGlobal}`];
        const { firstname, lastname, name } = data.find(obj => obj.id === this.selection[entity]);
        return (segment) ? name : `${firstname} ${lastname}`;
      }
      return '';
    },
    titleTransfer() {
      return this.$t('call.transfer.transfer_to', { name: this.nameSelected });
    },
    departmentsTableColumns() {
      return departmentsColumns.columns;
    },
    agentsTableColumns() {
      return agentsColumns.columns;
    },
  },
  methods: {
    transfer(entity, id) {
      this.selection = { segment: null, agent: null };
      this.$store.commit('call/transfer', { agent: '', segment: '' });
      const selections = this.$store.getters['call/transfer'];
      selections[entity.slice(0, -1)] = id;
      this.selection[entity.slice(0, -1)] = id;
      this.$store.commit('call/transfer', selections);
    },
    changeTransferEntity(index) {
      this.selectedEntity = index === '1' ? 'segments' : 'agents';
    },
    async refresh() {
      this.loadingRefresh = true;
      await this.$refs[`${this.selectedEntity}-table`].handleData().catch(e => e);
      this.loadingRefresh = false;
    },
    capitalize(text) {
      return text.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
    },
  },
};
</script>

<style>
  .empty-list {
    width: 100%;
    height: 100px;
    padding: 5% 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .empty-list i {
    font-size: 1.3em;
    margin-bottom: 10px;
  }

  .header-modal-transfer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

  }

  .header-modal-transfer [subtitle] {
    font-size: 1em;
    font-weight: normal;
    margin-top: 5px;
  }

  .footer-modal-call {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .entity-selected {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0 10px 0;
  }

  .entity-selected .name-entity {
    margin-left: 10px;
  }

  td.at-table__cell, .agent-name{
    font-size: 0.9em;
  }

  .agent-name {
    text-transform: capitalize;
  }

  .privilege-name {
    font-size: 0.8em;
    margin-top: 3px;
    text-transform: capitalize;
  }

  .at-table__cell.at-table__column::first-letter {
    text-transform: capitalize;
  }

  .at-tabs-nav__item {
    text-transform: capitalize;
  }

  .pill-status {
    width: 0.8em;
    min-width: 0.8em;
    max-width: 0.8em;
    height: 0.8em;
    min-height: 0.8em;
    max-height: 0.8em;
    border-radius: 100%;
  }

  .pill-status.online {
    background: #13ce66;
  }

  .pill-status.occupied {
    background: #ffc82c;
  }

  .pill-status.offline {
    background: #ff4949;
  }

  .capitalize {
    text-transform: capitalize;
  }

  @media screen and (min-width: 800px) {
    .modal-max-width {
      max-width: 600px;
    }
  }
</style>
