export default {
  en: {
    types: {
      scheduled: 'Scheduled',
      blocked: 'Hour blocked',
    },
    status: {
      pending: 'Pending',
      canceled: 'Canceled',
      attended: 'Attended',
      rescheduled: 'Rescheduled',
    },
    canceledBy: {
      agent: 'Agent',
      customer: 'Customer',
    },
    errors: {
      services: 'No calendars available for your user',
      form: 'There no form set form the current calendar',
      schedule: 'Can\'t schedule, check the errors',
      reschedule: 'Rescheduled failed',
      links: 'Can\'t get the links',
    },
    rescheduled: 'Reschedule successfully',
    schedule: 'Schedule',
    reschedule: 'Reschedule',
    timezoneFilter: 'Write to filter the timezone by city',
    timezoneWarning: 'The timezone should match with the customer location'
  },
  es: {
    types: {
      scheduled: 'Agendada',
      blocked: 'Bloqueo de horario',
    },
    status: {
      pending: 'Pendiente',
      canceled: 'Cancelada',
      attended: 'Atendida',
      rescheduled: 'Reagendada',
    },
    canceledBy: {
      agent: 'Agente',
      customer: 'Cliente',
    },
    errors: {
      services: 'No hay calendarios asociados a tu usuario',
      form: 'No hay un formulario configurado para el calendario',
      schedule: 'No se puede agendar, verifique los errores',
      reschedule: 'Reagendamiento fallido',
      links: 'No se pudo obtener los enlaces',
    },
    rescheduled: 'Reagendamiento exitoso',
    schedule: 'Agendar',
    reschedule: 'Reagendar',
    timezoneFilter: 'Escriba para filtrar las zonas horarias por ciudad',
    timezoneWarning: 'La zona horaria debe coincidir con la ubicación del cliente'
  },
  fr: {
    types: {
      scheduled: 'Planifiée',
      blocked: 'Blocage horaire',
    },
    status: {
      pending: 'En attente',
      canceled: 'Annulée',
      attended: 'Assistée',
      rescheduled: 'Replanifiée',
    },
    canceledBy: {
      agent: 'Agent',
      customer: 'Client',
    },
    errors: {
      services: 'Aucun calendrier associé à votre utilisateur',
      form: 'Aucun formulaire configuré pour le calendrier',
      schedule: 'Impossible de planifier, veuillez vérifier les erreurs',
      reschedule: 'Échec de la replanification',
      links: 'Impossible d\'obtenir les liens',
    },
    rescheduled: 'Replanification réussie',
    schedule: 'Planifier',
    reschedule: 'Replanifier',
    timezoneFilter: 'Entrez pour filtrer les fuseaux horaires par ville',
    timezoneWarning: 'Le fuseau horaire doit correspondre à l\'emplacement du client'
}

};
