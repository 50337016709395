import Vue from 'vue';

function muteAll() {
  if (!document.querySelector('videsk-webrtc')) return;
  document.querySelector('videsk-webrtc').audio = !document.querySelector('videsk-webrtc').audio;
  document.querySelector('videsk-webrtc').video = !document.querySelector('videsk-webrtc').video;
}

function microphone() {
  if (!document.querySelector('videsk-webrtc')) return;
  document.querySelector('videsk-webrtc').audio = !document.querySelector('videsk-webrtc').audio;
}

function camera() {
  if (!document.querySelector('videsk-webrtc')) return;
  document.querySelector('videsk-webrtc').video = !document.querySelector('videsk-webrtc').video;
}

const shortcuts = {
  t: muteAll,
  c: camera,
  m: microphone,
};

function listeners() {
  document.addEventListener('keydown', (event) => {
    const { altKey, key } = event;
    if (!altKey) return;
    // eslint-disable-next-line consistent-return
    if (key in shortcuts) return shortcuts[key]();
  });
}

Vue.prototype.$shortcuts = listeners;

export default listeners;
