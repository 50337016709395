export default {
  en: {
    join: 'Join to meeting',
    checkNewMeeting: 'View new meeting',
    newMeeting: 'New meeting',
    canceledBy: 'Canceled by',
    canceledAt: 'Canceled at',
    canceledReason: 'Canceled reason',
    accessUrl: 'Access URL',
    modifyUrl: 'Cancel or reschedule URL',
    scheduledSince: 'Scheduled since',
    cancelMeeting: 'Cancel meeting',
    canceledReasonPlaceholder: 'Tell the client the reason for the cancellation.',
    meetingTitle: 'Meeting of {name}',
    blockedTitle: 'Blocked time for {name}',
    contactFormTitle: 'Contact information',
    rescheduleMeeting: 'Reschedule',
  },
  es: {
    join: 'Unirse a la reunión',
    checkNewMeeting: 'Ver new meeting',
    newMeeting: 'Nueva reunión',
    canceledBy: 'Cancelado por',
    canceledAt: 'Cancelado el',
    canceledReason: 'Razón de cancelación',
    accessUrl: 'URL de acceso',
    modifyUrl: 'URL de cancelación y reagendamiento',
    scheduledSince: 'Agendado desde',
    cancelMeeting: 'Cancelar reunión',
    canceledReasonPlaceholder: 'Indica al cliente el motivo de la cancelación',
    meetingTitle: 'Reunión de {name}',
    blockedTitle: 'Blocked time for {name}',
    contactFormTitle: 'Información de contacto',
    rescheduleMeeting: 'Reagendar',
  },
  fr: {
    join: 'Rejoindre la réunion',
    checkNewMeeting: 'Voir nouvelle réunion',
    newMeeting: 'Nouvelle réunion',
    canceledBy: 'Annulé par',
    canceledAt: 'Annulé le',
    canceledReason: 'Raison de l\'annulation',
    accessUrl: 'URL d\'accès',
    modifyUrl: 'URL d\'annulation et de reprogrammation',
    scheduledSince: 'Planifié depuis',
    cancelMeeting: 'Annuler la réunion',
    canceledReasonPlaceholder: 'Indiquez au client le motif de l\'annulation',
    meetingTitle: 'Réunion de {name}',
    blockedTitle: 'Temps bloqué pour {name}',
    contactFormTitle: 'Informations de contact',
    rescheduleMeeting: 'Reprogrammer',
}

}
