import Vue from 'vue';
import VirtualBackground from '@videsk/virtual-background';

const virtualBackground = new VirtualBackground();

window.virtualBackground = virtualBackground;

Vue.prototype.$virtualBackground = virtualBackground;

export default {
  virtualBackground,
  VirtualBackground,
};
