export default {
  en: {
    department: 'segment | segments',
    agent: 'agent | agents',
    call: 'call | calls',
    calendar: 'calendar | calendars'
  },
  es: {
    department: 'segmento | segmentos',
    agent: 'agente | agentes',
    call: 'llamada | llamadas',
    calendar: 'calendario | calendarios'
  },
  fr: {
    department: 'segment | segments',
    agent: 'agent | agents',
    call: 'appel | appels',
    calendar: 'calendrier | calendriers'
}

};
