<template>
  <div class="info-call-container">
    <a-row :gutter="24">
      <a-col :span="24">
        <a-dropdown>
          <a-menu slot="overlay" @click="actionsStatesClicks">
            <a-menu-item key="start"><a-icon type="play-circle" /> Iniciar</a-menu-item>
            <a-menu-item key="retry"><a-icon type="redo" /> Reiniciar proceso</a-menu-item>
            <a-menu-item key="stop"><a-icon type="issues-close" /> Detener</a-menu-item>
          </a-menu>
          <a-button> Acciones <a-icon type="down" /> </a-button>
        </a-dropdown>
      </a-col>
      <a-col :span="24">
       <a-collapse accordion style="margin: 1rem 0 2rem 0;" activeKey="logs">
         <a-collapse-panel key="devices" :header="`${$t('apps.deviceStatus')}`">
         <div class="url-params-container">
           <div
             class="url-params-item"
             v-for="(value, key) in devicesStatus"
             :key="key"
           >
             <div class="url-params-key">
               {{ key }}
             </div>
             <div class="url-params-value">
               <pre style="padding: 0.4rem 0.5rem;"><code>{{ transformDeviceStatus(value) }}</code></pre>
             </div>
           </div>
         </div>
       </a-collapse-panel>
       <a-collapse-panel key="logs" :header="`${$t('apps.logs')}`">
         <div class="url-params-container">
           <a-timeline>
             <a-timeline-item
              v-for="(log, index) in logs"
              :color="statusCodeToColor[log.status] || 'gray'"
             :key="index"
             >
               {{ log && log.message || 'Unknown' }}
             </a-timeline-item>
           </a-timeline>
         </div>
       </a-collapse-panel>
       <a-collapse-panel key="document" :header="$t('apps.documentLog')" v-if="documentData">
         <small v-show="Object.keys(documentData).length === 0">Etapa aún no iniciada</small>
         <div class="url-params-container" v-show="Object.keys(documentData).length > 0">
           <div
             class="url-params-item"
             v-for="(value, key) in documentData"
             :key="key"
           >
             <div class="url-params-key">
               {{ key }}
             </div>
             <div class="url-params-value">
               <pre style="padding: 0.4rem 0.5rem;"><code>{{ value }}</code></pre>
             </div>
           </div>
         </div>
       </a-collapse-panel>
       <a-collapse-panel key="fingerprint" :header="$t('apps.fingerprintLog')" v-if="fingerprintData">
         <small v-show="Object.keys(fingerprintData).length === 0">Etapa aún no iniciada</small>
         <div class="url-params-container">
           <div
             class="url-params-item"
             v-for="(value, key) in fingerprintData"
             :key="key"
           >
             <div class="url-params-key">
               {{ key }}
             </div>
             <div class="url-params-value">
               <pre style="padding: 0.4rem 0.5rem;"><code>{{ value }}</code></pre>
             </div>
           </div>
         </div>
       </a-collapse-panel>
     </a-collapse>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'apps',
  data: () => ({
    documentData: {},
    fingerprintData: {},
    logs: [{ status: 1, message: 'Proceso no iniciado' }],
    devicesStatus: { barcode: false, nfc: false, fingerprint: false },
  }),
  computed: {
    socket() {
      return this.$store.getters['global/socket'];
    },
    appSettings() {
      return this.$store.getters['global/settings']?.apps;
    },
    statusCodeToColor() {
      return {
        0: 'red',
        1: 'gray',
        2: 'blue',
        3: 'green'
      }
    }
  },
  mounted() {
    this.socket.on('apps:message', event => {
      const { appEvent, data } = event;
      if (appEvent === 'id:scan') this.documentData = data;
      else if (appEvent === 'fingerprint:scan') this.fingerprintData = data;
      else if (appEvent === 'status') {
        this.logs.push(data);
        const { status } = data
        if (status < 2) this.clearResults();
      }
      else if (appEvent === 'devices:status') this.devicesStatus = data;
    });
  },
  methods: {
    actionsStatesClicks(event) {
      const { key } = event;
      if (key === 'start') this.logs.push({ status: 1, message: 'Inicializando' });
      this.socket.emit('apps:message', { appEvent: key, data: {}, name: 'TOC', options: this.appSettings[0] });
    },
    transformDeviceStatus(boolean) {
      return boolean ? 'Activo' : 'Desactivado';
    },
    clearResults() {
      this.documentData = {};
      this.fingerprintData = {};
    }
  },
};
</script>

<style>
.info-call-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 5%;
}

.app-params-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.app-params-key {
  width: 20%;
  font-weight: 700;
  color: #2d2d2d;
}

.app-params-key:first-letter {
  text-transform: uppercase;
}

.app-params-value {
  width: 75%;
  background: #edeef1;
  color: #2f2f2f;
  border-radius: 5px;
}

pre {
  background: #dde1e7;
  color: #444443;
  padding: 0.8rem 0.6rem;
  border-radius: 5px;
}

pre code {
  margin: 0;
  padding: 0;
}

.source-details {
  display: flex;
  flex-direction: column;
}

.source-details .source-item {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}

.source-details div:first-child {
  margin-top: 0;
}

.source-details div:last-child {
  margin-bottom: 0;
}

.source-details .source-item .separator {
  margin: 0 1rem;
}

.source-item {
  width: fit-content;
}
</style>
