import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';
import options from './environments';

Axios.defaults.baseURL = options.http.hostname;
Axios.defaults.headers.post['Content-Type'] = 'application/json';


Vue.use(VueAxios, Axios);

export default Axios;
