function getConversion(value) {
  if (value <= 1) return value * 60;
  if (value >= 1 && value < 60) return value;
  return value / 60;
}

function getUnit(value) {
  if (value <= 1) return 'second';
  if (value >= 1 && value < 60) return 'minute';
  return 'hour';
}

function convert(value = 0) {
  return { value: getConversion(value).toFixed(2), unit: getUnit(value) };
}

module.exports = {
  getConversion,
  getUnit,
  convert,
};
