import i18n from '@/plugins/i18n';

const columns = [
  {
    title: i18n.tc('entities.department', 1),
    dataIndex: 'name',
    width: '80%',
  },
  {
    title: '',
    dataIndex: 'id',
    width: '20%',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  columns,
};
