import Vue from 'vue';
import EmptyTemplate from '../../components/empty.vue';

const state = {
  view: EmptyTemplate,
  currentView: '',
  views: {
    info: Vue.component('form-call', () => import(/* webpackChunkName: "formCall" */ '../../views/call/info-call.vue')),
    fileshare: Vue.component('fileshare-call', () => import(/* webpackChunkName: "fileShareCall" */ '../../views/call/fileshare.vue')),
    chat: Vue.component('chat', () => import(/* webpackChunkName: "chatCall" */ '../../views/call/chat.vue')),
    'agent-form': Vue.component('agent-form', () => import(/* webpackChunkName: "agentFormCall" */ '../../views/call/agent-form.vue')),
    capture: Vue.component('capture-image', () => import(/* webpackChunkName: "agentFormCall" */ '../../views/call/capture.vue')),
    'custom-apps': Vue.component('custom-apps', () => import(/* webpackChunkName: "customApps" */ '../../views/call/apps/index.vue')),
  },
};

const mutations = {
  view(state, value) {
    state.currentView = value;
    if (state.view !== state.views[value]) state.view = state.views[value];
  },
};

const actions = {

};

const getters = {
  currentView: state => state.currentView,
  view: state => state.view,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
