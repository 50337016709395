<template>
  <div class="inbound-call-container">
    <div class="inbound-call-info">
      <div class="inbound-title">
        {{ title }}
      </div>
      <div class="inbound-department">
        {{ nameToShow }}
      </div>
      <div
        class="inbound-transferred-call"
        v-show="isTransferred"
      >
        <div class="container-transferred">
          <div
            class="direct-transfer"
            v-show="!isDifferentDepartment"
          >
            {{ $t('call.title.type_call.direct', { name: $store.getters['call/agent'] }) }}
          </div>
          <div
            class="different-department"
            v-show="isDifferentDepartment"
          >
            <div class="middle-container">
              {{ (isTransferred) ? $store.getters['call/segmentPrevious'] : '' }}
            </div>
            <div style="width: 1.2em;">
              <a-icon
                type="fast-forward"
              />
            </div>
            <div class="middle-container">
              {{ $store.getters['call/segment'] }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="inbound-vip-tag"
        v-show="$store.getters['call/priority']"
      >
        VIP
      </div>
      <div class="item-inbound-details" v-if="details.branch">
        <a-icon type="bank" /> {{ details.branch.label }}
      </div>
      <div class="item-inbound-details" v-if="details.ticketId">
        <a-icon type="number" /> {{ details.ticketId }}
      </div>
      <div class="item-inbound-details" v-if="details.poc">
        <a-icon type="desktop" /> {{ details.poc.label }}
      </div>
    </div>
    <div class="inbound-actions-container">
      <div
        class="inbound-call-btn dismiss"
        @click="dismiss"
      >
        <a-icon type="phone" />
      </div>
      <div
        class="inbound-call-btn answer"
        @click="answer"
      >
        <a-icon type="phone" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    details() {
      return this.$store.getters['call/inboundData'] || {};
    },
    isTransferred() {
      return this.$store.getters['call/transferred'];
    },
    title() {
      const key = this.$store.getters['call/invitation'] ? 'invitation' : 'inbound';
      return this.$t(`call.title.${key}`);
    },
    nameToShow() {
      return (!this.$store.getters['call/invitation']) ? this.$store.getters['call/segment'] : this.$store.getters['call/agent'];
    },
    isDifferentDepartment() {
      const transferredDepartmentName = (this.isTransferred) ? this.$store.getters['call/transferred'].segment : '';
      return transferredDepartmentName !== this.$store.getters['call/segment'];
    },
  },
  methods: {
    answer() {
      this.$store.dispatch('call/answer');
      this.$mixpanel.track('Answer');
      window.answered = new Date();
    },
    dismiss() {
      this.$store.dispatch('call/dismiss');
      this.$mixpanel.track('Dismiss');
    },
  },
};
</script>

<style>
.inbound-call-container{
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.inbound-call-info {
  width: 100%;
  height: auto;
  padding-bottom: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inbound-call-info * {
  line-height: 1.5;
  user-select: none;
}

.inbound-title {
  font-size: 2.7em;
  font-weight: 500;
}

.inbound-department {
  font-size: 1.5em;
}

.inbound-actions-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 7%;
}

  .inbound-call-btn {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6cd034;
    cursor: pointer;
    transition: all .5s ease;
    filter: brightness(0.7);
    color: white;
    font-size: 2em;
  }

  .inbound-call-btn:hover{
    filter: brightness(1);
  }

  .inbound-call-btn.dismiss {
    background: #e63e3e;
  }

  .inbound-call-btn > .icon {
    width: 60%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100%;
  }

  .inbound-call-btn.dismiss > .icon {
    transform: rotate(136deg);
  }

  .inbound-vip-tag {
    width: 40px;
    background: linear-gradient(120deg, #ffff9f 0%, #726135 100%);
    color: white;
    font-weight: bold;
    font-size: 1em;
    padding: 2px 3px;
    border-radius: 3px;
    text-align: center;
    margin-top: 10px;
    opacity: 0.7;
  }

  .inbound-transferred-call {
    width: 100%;
    padding: 0 2em;
    margin-top: 20px;
  }

  .container-transferred {
    background: #faad14;
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .different-department {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .middle-container {
    width: calc(50% - 1em);
    min-width: calc(50% - 1em);
    max-width: calc(50% - 1em);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: none;
  }

  .direct-transfer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: none;
  }

  .item-inbound-details {
    display: flex;
    align-items: center;
    margin: 0.2rem 0;
  }

  .item-inbound-details i {
    margin-right: 1rem;
  }
</style>
