import Vue from 'vue';
import * as Sentry from '@sentry/vue';

Sentry.init({
  Vue,
  dsn: 'https://e29ac6b3190343fabfba1151eb148cea@o229478.ingest.sentry.io/1876121',
});

Vue.prototype.$sentry = Sentry;

export default Sentry;
