import Vue from 'vue';
import Vuex from 'vuex';
import global from '@/store/modules/global';
import createPlugin from 'logrocket-vuex';
import recordings from './modules/recordings';
import call from './modules/call';
import components from './modules/components';
import calendar from './modules/calendar';
import LogRocket from '../plugins/logrocket';

const logRocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global,
    call,
    components,
    calendar,
    recordings,
  },
  plugins: [logRocketPlugin]
});
