import Vue from 'vue';
import './plugins/environments';
import './plugins/axios';
import auth from '@/plugins/auth';
import i18n from '@/plugins/i18n';
import router from '@/plugins/vue-router';
import 'ant-design-vue/dist/antd.css';
import '@/plugins/moment';
import './plugins/at-ui';
import './plugins/mixpanel';
import WebRTC from '@videsk/webrtc-sdk';
import './plugins/virtual-background';
import App from './App.vue';
import store from './store/index';
import './plugins/ant-design';
import './plugins/dayJS';
import './plugins/time-format';
import './plugins/sentry';
import './plugins/picture-in-picture';
import './plugins/shorcuts';
import './plugins/timer';
import './plugins/timers';
import rocket from './plugins/logrocket';
import 'tui-calendar/dist/tui-calendar.css';
import unitConverter from './plugins/unit-converter';

/* CSS import */
require('@/assets/css/main.css');
require('@/assets/css/el-override.css');

Vue.config.productionTip = false;
// eslint-disable-next-line no-undef
Vue.prototype.$authorization = auth;
Vue.prototype.$rocket = rocket;
Vue.prototype.$calendar = null;
Vue.prototype.$isApple = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(window.navigator.platform) || (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document);
Vue.prototype.$WebRTC = WebRTC;
Vue.prototype.$unitConverter = unitConverter;

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app');
