<template>
  <a-modal
    :visible="show"
    :closable="true"
    :mask-closable="false"
    :centered="true"
    :cancel-text="$t('cancel')"
    :ok-text="InviteButtonText"
    @cancel="cancel"
    @ok="confirm"
    :ok-button-props="{ props: { disabled: !agent, loading } }"
    :cancel-button-props="{ props: { disabled: loading } }"
    class="modal-max-width"
  >
    <template
      slot="title"
    >
      <div style="display: flex; align-items: center; justify-content: space-between; padding-right: 20px;">
        <div>
          <h3 style="font-size: 1em; margin-bottom: 5px;">
            {{ $t('call.invite.title') }}
          </h3>
          <p style="font-size: 0.9em; color: gray;">
            {{ $t('call.invite.subtitle') }}
          </p>
        </div>
        <a-button
          :loading="loadingRefresh"
          @click="refresh"
        >
          {{ $t('refresh') }}
        </a-button>
      </div>
    </template>
    <template-table
      ref="agents-table"
      entity="agents"
      :columns="agentsTableColumns"
      :select="invite"
    />
    <a-alert
      v-show="agent"
      :message="inviteAlertTile"
      :description="$t('call.invite.alert.subtitle')"
      type="info"
      show-icon
    />
  </a-modal>
</template>

<script>
import templateTable from '@/components/call/tables/table.vue';
import agentsColumns from './tables/columns-agents';

export default {
  components: {
    templateTable,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    confirm: {
      type: Function,
      required: true,
    },
    cancel: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    disabledRefresh: false,
    loadingRefresh: false,
    agent: null,
  }),
  computed: {
    InviteButtonText() {
      return (!this.loading) ? this.$t('invite') : this.$t('call.invite.waiting');
    },
    agentsTableColumns() {
      return agentsColumns.columns;
    },
    nameSelected() {
      if (this.agent) {
        const { data } = this.$store.getters['global/agents'];
        const { firstname, lastname } = data.find(obj => obj.id === this.agent);
        return `${firstname} ${lastname}`;
      }
      return '';
    },
    inviteAlertTile() {
      return this.$t('call.invite.alert.title', { name: this.nameSelected });
    },
  },
  methods: {
    async refresh() {
      this.loadingRefresh = true;
      await this.$refs['agents-table'].handleData();
      this.loadingRefresh = false;
    },
    invite(entity, id) {
      this.agent = id;
      this.$store.commit('call/invite_agent', id);
    },
    capitalize(text) {
      return text.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
    },
  },

};
</script>

<style>
  .capitalize {
    text-transform: capitalize;
  }
</style>
