<template>
  <div style="width: 100%; height: 100%;">
    <div class="w-full p-10" style="text-align: center;" v-show="!isConnected">
      <h1 class="title" style="margin-bottom: 10px; line-height: 1;">{{ $t('call.beamport.capture.title') }}</h1>
      <p class="subtitle">{{ $t('call.beamport.capture.subtitle') }}</p>
      <a-button type="primary" style="margin-top: 30px;" :loading="connecting" @click="connect">{{ (connecting) ? $t('call.beamport.connecting') : $t('call.beamport.connect') }}</a-button>
    </div>
    <div class="manager-files-container" v-show="isConnected">
      <videsk-fileshare style="width: 100%; height: 100%;" id="capture-shot">
        <div class="shot-button" slot="input">
          <a-button
            @click="capture"
            :disabled="lock"
            :loading="lock"
            icon="camera"
          >Capturar</a-button>
        </div>
      </videsk-fileshare>
    </div>
  </div>
</template>

<script>
import '@videsk/fileshare-component';
import FileUpload from '@videsk/upload-chunk';
import BeamPort from '@videsk/beamport';

export default {
  name: 'capture',
  data: () => ({
    connecting: false,
    isConnected: false,
    beamport: null,
    lock: false,
  }),
  computed: {
    socket() {
      return this.$store.getters['global/socket'];
    },
    isMeeting() {
      return this.$store.getters['calendar/onMeeting'];
    },
    identifier() {
      return this.$store.getters['call/id'];
    }
  },
  watch: {
    isConnected() {
      this.connecting = false;
    },
  },
  mounted() {
    document.addEventListener('customer:disconnect', () => {
      if (this.component) this.component.disabled = true;
    }, { once: true });
    document.addEventListener('beamport:files:connect:capture', event => {
      this.setupBeamPort(event.detail);
    }, { once: true });

    this.component = document.querySelector('videsk-fileshare#capture-shot');
    this.component.addEventListener('download', event => {
      const { id } = event.detail;
      const element = this.component.get(id);
      if (element) element.download();
    });
    this.component.addEventListener('preview', event => {
      const { file } = event.detail;
      document.querySelector('videsk-fileshare-viewer').data = file;
    });
    this.component.addEventListener('save', async event => {
      const { file, id } = event.detail;
      const element = this.component.get(id);
      element.state = 'saving';
      const accessToken = await this.getUploadToken(file);
      if (!accessToken) return;
      const uploader = new FileUpload(file, accessToken, this.$fileOptions.http);
      uploader.addEventListener('progress', event => {
        element.percentage = event.detail;
      });
      uploader.upload().catch(() => {
        this.$notification.error({ description: this.$t('call.saveFiles.errorSaving') });
        element.state = 'received';
      });
    });
  },
  methods: {
    async connect() {
      this.connecting = true;
      this.socket.emit('beamport:open', { channel: 'capture' });
    },
    setupBeamPort(event) {
      const { accessToken, channel } = event;

      if (channel !== 'capture') return;

      this.beamport = new BeamPort({ websocket: this.$signalingOptions.websocket });
      this.beamport.connect(accessToken);
      this.beamport.addEventListener('description', event => {
        const beamPortFile = event.detail;
        const file = this.component.create(beamPortFile.data, `${beamPortFile.filename}.png`, { size: beamPortFile.size, type: beamPortFile.mimeType });
        const element = this.component.add(file, beamPortFile.crc32, 'receiving', true);
        element.upload = true;
      });
      this.beamport.addEventListener('progress', event => {
        const { percentage, crc32 } = event.detail;
        const element = this.component.get(crc32);
        if (element) element.percentage = percentage;
      });
      this.beamport.addEventListener('disconnected', () => {
        this.component.disabled = !this.beamport.connected;
      });
      this.beamport.addEventListener('connected', () => {
        this.component.disabled = !this.beamport.connected;
      });
      this.beamport.addEventListener('ports', () => {
        this.component.disabled = !this.beamport.connected;
      });
      this.isConnected = true;
      if (this.component) this.component.disabled = false;
    },
    async capture() {
      this.socket.emit('capture:shot');
      this.lock = true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.lock = false;
    },
    async getUploadToken(file) {
      const FileItem = customElements.get('videsk-file-item');
      const { type, name, size } = file;
      const arrayBuffer = await file.arrayBuffer();
      const crc32 = FileUpload.calculateCRC32(arrayBuffer);
      const identifierEntity = this.isMeeting ? 'appointment': 'call';
      const payload = { size, crc32, extension: FileItem.getFileExtension(name), type: type.split('/')[1] || 'image' };
      payload[identifierEntity] = this.identifier;
      const response = await this.$http.post('/files/authorization', payload).catch(e => e);
      if (!(response instanceof Error)) return response.data.accessToken;
      this.$notification.error({ message: this.$t('call.saveFiles.noAccess') });
    }
  },
};
</script>

<style>
.w-full {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-10 {
  padding: 1em;
}

.manager-files-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-select-selection__rendered {
  display: flex;
  align-items: center;
}

.shot-button {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
