

const state = {
  show: false,
  appointments: [],
  new: null,
  currentDate: new Date(),
  inMeeting: null,
  isReachingStatus: false,
  latestMeeting: null,
  openAppointmentDrawer: false,
  appointmentData: {},
  services: [],
  onMeeting: false,
  mandatoryTags: false,
};

const mutations = {
  show(state, value) {
    state.show = value;
  },
  appointments(state, value) {
    state.appointments = value;
  },
  new(state, value) {
    state.new = value;
  },
  currentDate(state, value) {
    state.currentDate = value;
  },
  inMeeting(state, value) {
    state.inMeeting = value;
  },
  isReachingStatus(state, value) {
    state.isReachingStatus = value;
  },
  latestMeeting(state, value) {
    state.latestMeeting = value;
  },
  openAppointmentDrawer(state, value) {
    state.openAppointmentDrawer = value;
  },
  appointmentData(state, value) {
    state.appointmentData = value;
  },
  setAppointmentKey(state, { key, value }) {
    state.appointmentData[key] = value;
  },
  services(state, value) {
    state.services = value;
  },
  onMeeting(state, value) {
    state.onMeeting = value;
  },
  mandatoryTags(state, value) {
    state.mandatoryTags = value;
  },
};

const actions = {
  checkMeeting({ dispatch, commit, rootGetters }) {
    const settings = rootGetters['global/settings'];
    const socket = rootGetters['global/socket'];
    if (!settings.calendarEnabled) return;
    socket.emit('appointments:check');
    commit('isReachingStatus', true);
    return setTimeout(() => dispatch('checkMeeting'), 5000);
  },
  getAppointment({ rootGetters, getters }, appointmentId) {
    const socket = rootGetters['global/socket'];
    const { type } = getters.appointments?.find(appointment => appointment.id === appointmentId) || {};
    if (type === 'external') return;
    socket.emit('appointments:get', { appointment: appointmentId });
  },
  patchAppointment({ rootGetters }, data) {
    const { id, startAt, endAt } = data;
    const socket = rootGetters['global/socket'];
    socket.emit('appointments:patch', { id, startAt, endAt });
  },
  cancel({ rootGetters }, data) {
    const { id, cancelReason } = data;
    const socket = rootGetters['global/socket'];
    socket.emit('appointments:remove', { id, cancelReason });
  },
  joinMeeting({ getters, rootGetters }) {
    const socket = rootGetters['global/socket'];
    socket.emit('meeting:join', { appointment: getters.appointmentData._id });
  },
  endMeeting({ rootGetters }) {
    const status = rootGetters['global/statusHangup'];
    const socket = rootGetters['global/socket'];
    socket.emit('meeting:hangup', { status });
  },
};

const getters = {
  show: state => state.show,
  appointments: state => state.appointments,
  new: state => state.new,
  currentDate: state => state.currentDate,
  inMeeting: state => state.inMeeting,
  isReachingStatus: state => state.isReachingStatus,
  latestMeeting: state => state.latestMeeting,
  openAppointmentDrawer: state => state.openAppointmentDrawer,
  appointmentData: state => state.appointmentData,
  services: state => state.services,
  onMeeting: state => state.onMeeting,
  mandatoryTags: state => state.mandatoryTags,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
