import Vue from 'vue';

const timeFormat = (time) => {
  // Time is in seconds
  time = time || 0;
  let seconds = time % 60;
  let minutes = (time / 60) % 60;
  const hours = (time / 60) / 60;

  const numberToString = (value) => {
    value = Math.trunc(value);
    return (value < 10) ? `0${value}` : value;
  };

  seconds = (seconds < 60) ? seconds : 0;
  minutes = (minutes < 60) ? minutes : 0;
  // Return in object format
  return `${numberToString(hours)}:${numberToString(minutes)}:${numberToString(seconds)}`;
};

Vue.prototype.$timeFormat = timeFormat;
