<template>
  <div
    class="version-banner"
    v-show="visible && showBanner"
  >
    <div
      class="banner-container warning"
      v-show="isNotCompatible"
    >
      <div>{{ $t('browser.deprecatedMessage', { browserName }) }}</div>
      <small>{{ $t('browser.currentVersion') }}: {{ browserVersion }}</small>
      <div
        class="close-banner"
        @click="close"
      >
        {{ $t('close') }}
      </div>
    </div>
    <div
      class="banner-container critical"
      v-show="vulnerable"
    >
      <div>{{ $t('browser.deprecatedMessage', { browserName, browserVersion }) }}</div>
      <div
        class="close-banner"
        @click="close"
      >
        {{ $t('close') }}
      </div>
    </div>
  </div>
</template>

<script>
import RestrictedBrowsers from '@videsk/restricted-browsers';

export default {
  data: () => ({
    isNotCompatible: false,
    visible: true,
    browserName: '',
    browserVersion: '',
    vulnerable: false,
  }),
  computed: {
    showBanner() {
      return this.isNotCompatible || this.vulnerable;
    },
  },
  mounted() {
    this.unCompatible();
    this.isVulnerable();
  },
  methods: {
    async unCompatible() {
      const compatibility = {
        chrome: '<=90', edge: '<=90', firefox: '<=80', yandex: '*', instagram: '*', ie: '*', facebook: '*', safari: '<=12'
      };
      const browser = new RestrictedBrowsers({ compatibility });
      this.isNotCompatible = await browser.check();
      const { name, version } = browser.ua.browser;
      this.browserName = name;
      this.browserVersion = version;
    },
    async isVulnerable() {
      const browsers = { chrome: '<99.0.4844.84', edge: '<99.0.4844.84' };
      const browser = new RestrictedBrowsers({ compatibility: browsers });
      this.vulnerable = await browser.check();
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style>
.version-banner {
  width: 100%;
  max-width: 900px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.banner-container {
  width: 100%;
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  font-weight: 500;
}

.banner-container .close-banner {
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  filter: brightness(0.9);
}

.banner-container .close-banner:hover {
  filter: brightness(0.7);
}

.banner-container.warning, .banner-container.warning .close-banner {
  background-color: rgb(245 158 11);
}

.banner-container.critical, .banner-container.critical .close-banner {
  background-color: rgb(220 38 38);
}
</style>
