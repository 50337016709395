import i18n from '@/plugins/i18n';

const columns = [
  {
    title: '',
    dataIndex: 'status',
    width: '20%',
    scopedSlots: { customRender: 'status' },
    sorter: true,
  },
  {
    title: i18n.tc('entities.agent', 1),
    dataIndex: 'id',
    key: new Date().getTime().toString(),
    width: '60%',
    scopedSlots: {
      customRender: 'name',
      filterDropdown: 'filterAgent',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: '',
    dataIndex: 'id',
    width: '20%',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  columns,
};
