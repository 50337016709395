export default {
    en: {
        title: 'Generate personal link',
        segmentTooltip: 'Segment where customers will call',
        priorityTooltip: 'Priority for the customer in the line',
        referrerTooltip: 'Website to use for generate the call',
        notBeforeTooltip: 'Define the time that needs to be pass before use the link',
    },
    es: {
        title: 'Generar link personal',
        segmentTooltip: 'Segmento donde clientes llamarán',
        priorityTooltip: 'Prioridad para este enlace',
        referrerTooltip: 'Sitio web donde se realizará la llamada',
        notBeforeTooltip: 'Define un tiempo que deba pasar antes de usar el link',
    },
  fr: {
    title: 'Générer un lien personnel',
    segmentTooltip: 'Segment où les clients appelleront',
    priorityTooltip: 'Priorité pour ce lien',
    referrerTooltip: 'Site web où l\'appel sera effectué',
    notBeforeTooltip: 'Définit un délai avant d\'utiliser le lien',
}

}
