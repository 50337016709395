export default {
    en: {
        errors: {
            notFoundTitle: 'Recording not found',
            notFoundDescription: 'We have not been able to access the recording, it may have been deleted or we may have problems accessing it. If you believe this is an error please contact us at support@videsk.io.'
        },
        rangePlaceholder: 'Select a period',
        rangeStart: 'Date start',
        rangeEnd: 'Date end',
        forbidden: 'You haven\'t access to recordings',
        playerTitle: 'Recording player',
        statuses: {
            recording: 'Recording',
            processing: 'Processing',
            failed: 'Failed',
            pending: 'Pending',
            approved: 'Approved',
            rejected: 'Rejected',
        },
    },
    es: {
        errors: {
            notFoundTitle: 'Grabación no encontrada',
            notFoundDescription: 'No hemos podido acceder a la grabación, es posible que haya sido eliminada o tengamos problemas para acceder a ella. Si crees que esto es un error contáctanos a support@videsk.io.'
        },
        rangePlaceholder: 'Select un periodo',
        rangeStart: 'Fecha de inicio',
        rangeEnd: 'Fecha de fin',
        forbidden: 'No tienes acceso a las grabaciones',
        playerTitle: 'Reproductor de grabaciones',
        statuses: {
            recording: 'Grabando',
            processing: 'Procesando',
            failed: 'Procesamiento fallido',
            pending: 'Pendiente',
            approved: 'Aprobado',
            rejected: 'Rechazado',
        },
    },
  fr: {
    errors: {
        notFoundTitle: 'Enregistrement non trouvé',
        notFoundDescription: 'Nous n\'avons pas pu accéder à l\'enregistrement, il se peut qu\'il ait été supprimé ou que nous rencontrions des problèmes pour y accéder. Si vous pensez qu\'il s\'agit d\'une erreur, contactez-nous à support@videsk.io.'
    },
    rangePlaceholder: 'Sélectionner une période',
    rangeStart: 'Date de début',
    rangeEnd: 'Date de fin',
    forbidden: 'Vous n\'avez pas accès aux enregistrements',
    playerTitle: 'Lecteur d\'enregistrements',
    statuses: {
        recording: 'Enregistrement',
        processing: 'En cours de traitement',
        failed: 'Échec du traitement',
        pending: 'En attente',
        approved: 'Approuvé',
        rejected: 'Rejeté',
    },
}

}
