import ParamsParser from '@videsk/params-parser';
import NotificationAPI from '@/plugins/notification';
import HumanizeDate from '@videsk/humanize-date';
import { v4 as uuidv4 } from 'uuid';
import auth from '@/plugins/auth';
import axios from '../../plugins/axios';
import devOptions from '../../plugins/environments';
import timer from '../../plugins/timer';
import SocketWorker from '../../plugins/websocket';

function session() {
  const session = window.sessionStorage.getItem('console-session') || uuidv4();
  window.sessionStorage.setItem('console-session', session);
  return session;
}

const state = {
  virtualBackgroundGlobal: null,
  globalCameraStream: new MediaStream(),
  sessionId: session(),
  numberOfDisconnections: 0,
  isHTTPNetwork: true,
  disconnectReason: '',
  disconnectionLogs: [],
  measureNetwork: true,
  jitter: 0,
  forcedDisconnected: false,
  account: null,
  id: null,
  email: null,
  time: null,
  segments: {},
  agents: {},
  history: {},
  forms: [],
  metrics: {},
  token: null,
  socket: null,
  available: false,
  disconnected: true,
  inCall: false,
  response: {
    transfer_successful: { status: false, reason: '' },
    transfer_failed: { status: false, reason: '' },
    transferAgentFail: { status: false, reason: '' },
    transferSegmentFail: { status: false, reason: '' },
    invitation_successful: { status: false },
    invitation_failed: { status: false, agent: '' },
  },
  reJoined: false,
  customerTimeout: false,
  customerJoined: false,
  customerDisconnected: false,
  invitedAgentHangup: false,
  connectionError: false,
  callTimeout: false,
  errorAnswering: false,
  hangupError: false,
  customerLeaveBefore: false,
  comments: {
    time: new Date().getTime(),
  },
  interval: null,
  metaData: {},
  settings: {},
  userSettings: {},
  refreshTokenExpired: false,
  isApple: ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
  webrtcInstance: null,
  notifications: [],
  statusHangup: '',
};

const mutations = {
  account(state, value) {
    state.account = value;
  },
  id(state, value) {
    state.id = value;
  },
  email(state, value) {
    state.email = value;
  },
  segments(state, value) {
    state.segments = value;
  },
  agents(state, value) {
    state.agents = value;
  },
  forms(state, value) {
    state.forms = value;
  },
  token(state, value) {
    state.token = value;
  },
  socket(state, value) {
    state.socket = value;
  },
  available(state, value) {
    state.available = value;
  },
  disconnected(state, value) {
    state.disconnected = value;
  },
  transfer_successful(state, value) {
    state.response.transfer_successful = value;
  },
  transfer_failed(state, value) {
    state.response.transfer_failed = value;
  },
  invitation_successful(state, value) {
    state.response.invitation_successful = value;
  },
  invitation_failed(state, value) {
    state.response.invitation_failed = value;
  },
  time(state, value) {
    state.time = value;
  },
  commentsTime(state, value) {
    state.comments.time = value;
  },
  interval(state, value) {
    state.interval = value;
  },
  inCall(state, value) {
    state.inCall = value;
  },
  transferAgentFail(state, value) {
    state.response.transferAgentFail = value;
  },
  transferSegmentFail(state, value) {
    state.response.transferSegmentFail = value;
  },
  reJoined(state, value) {
    state.reJoined = value;
  },
  customerTimeout(state, value) {
    state.customerTimeout = value;
  },
  customerJoined(state, value) {
    state.customerJoined = value;
  },
  customerDisconnected(state, value) {
    state.customerDisconnected = value;
  },
  invitedAgentHangup(state, value) {
    state.invitedAgentHangup = value;
  },
  connectionError(state, value) {
    state.connectionError = value;
  },
  callTimeout(state, value) {
    state.callTimeout = value;
  },
  errorAnswering(state, value) {
    state.errorAnswering = value;
  },
  hangupError(state, value) {
    state.hangupError = value;
  },
  customerLeaveBefore(state, value) {
    state.customerLeaveBefore = value;
  },
  metaData(state, value) {
    state.metaData = value;
  },
  settings(state, value) {
    state.settings = value;
  },
  userSettings(state, value) {
    state.userSettings = value;
  },
  refreshTokenExpired(state, value) {
    state.refreshTokenExpired = value;
  },
  forcedDisconnected(state, value) {
    state.forcedDisconnected = value;
  },
  disconnectReason(state, value) {
    state.disconnectReason = value;
  },
  disconnectionLogs(state, reason) {
    if (state.disconnectionLogs.length >= 3600) state.disconnectionLogs.shift();
    state.disconnectionLogs.push({ reason, timestamp: new Date() });
  },
  measureNetwork(state, value) {
    state.measureNetwork = value;
  },
  jitter(state, value) {
    state.jitter = value;
  },
  numberOfDisconnections(state, value) {
    state.numberOfDisconnections = value;
  },
  isHTTPNetwork(state, value) {
    state.isHTTPNetwork = value;
  },
  signalingToken(state, value) {
    state.signalingToken = value;
  },
  virtualBackgroundGlobal(state, value) {
    state.virtualBackgroundGlobal = value;
  },
  globalCameraStream(state, value) {
    state.globalCameraStream = value;
  },
  webrtcInstance(state, value) {
    state.webrtcInstance = value;
  },
  notifications(state, value) {
    state.notifications = value;
  },
  customerPermissions(state, value) {
    state.customerPermissions = value;
  },
  statusHangup(state, value) {
    state.statusHangup = value;
  },
};

const actions = {
  async segments({ commit }, params = '') {
    const response = await axios.get(`/segments${params}`);
    commit('segments', response.data);
  },
  async agents({ commit }, params = '') {
    const response = await axios.get(`/users${params}&level=5`);
    commit('agents', response.data);
  },
  async forms({ commit }) {
    const response = await axios.get('widget?$select[]=forms');
    const { total, data } = response.data;
    const form = total > 0 ? data[0].forms : [];
    commit('forms', form);
  },
  logout() {
    return axios.post('/auth/logout');
  },
  async loadUserSettings({ commit }) {
    const response = await axios.get('/user/settings');
    commit('userSettings', response.data);
  },
  async networkBackgroundCheck({ getters, dispatch }) {
    if (!getters.measureNetwork) return;
    setTimeout(() => dispatch('networkBackgroundCheck'), 2000);
  },
  connect({ commit, dispatch }) {
    const socket = new SocketWorker('/websocket.js');
    commit('socket', socket);
    socket.connect({ websocket: devOptions.websocket });
    dispatch('LoadResources');
    dispatch('events');
  },
  events({
    commit, dispatch, getters, rootGetters
  }) {
    commit('measureNetwork', true);
    dispatch('networkBackgroundCheck');
    const replaceAccessToken = () => auth.getTokens('accessToken');
    const getTimezone = () => {
      try {
        const weirdTimezones = {
          'etc/gmt+4': 'America/Santiago',
          'america/buenos_aires': 'America/Argentina/Buenos_Aires',
          'etc/unknown': 'UTC',
        };
        const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timezone.toLowerCase() in weirdTimezones ? weirdTimezones[timezone.toLowerCase()] : timezone;
      } catch (error) {
        console.error(error);
        return 'America/Santiago';
      }
    };

    getters.socket.on('authenticate', () => {
      getters.socket.emit('authenticate', {
        accessToken: replaceAccessToken(),
        sessionId: getters.sessionId,
        timezone: getTimezone(),
      });
    });

    getters.socket.on('notifications:request', () => getters.socket.emit('notification:request'));

    getters.socket.on('notifications:update', event => {
      const { notifications = [], total } = event;
      commit('notifications', notifications);
      if (total < 1) return;
      const customEvent = new CustomEvent('notification:update', { detail: { total }, bubbles: true });
      document.dispatchEvent(customEvent);
    });

    getters.socket.on('reconnect_attempt', attempt => commit('numberOfDisconnections', attempt));
    getters.socket.on('reconnect', () => commit('numberOfDisconnections', 0));
    getters.socket.on('reconnect_error', () => {
      if (getters.isHTTPNetwork && (getters.numberOfDisconnections < 10 || getters.disconnected)) return;
      document.dispatchEvent(new Event('offline:play'));
      new NotificationAPI().send('⛔ Sin conexión ⛔', 'Te has desconectado, intentamos conectarnos automáticamente pero al parecer hay un problema con la red.');
    });

    getters.socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect' && !window.sessionDuplicated) return dispatch('connect');
      timer.pause();
      const event = new CustomEvent('disconnected', { detail: reason });
      document.dispatchEvent(event);
      commit('disconnectReason', reason);
      commit('disconnectionLogs', reason);
      console.error(`Disconnection reason: ${reason}, for user: ${state.email} with session ${state.sessionId}.`);
      if (!rootGetters['call/active']) commit('disconnected', true);
      // commit('call/active', false, { root: true });
      document.dispatchEvent(new Event('disconnected'));
    });

    getters.socket.on('agent:authenticated', () => {
      getters.socket.emit('calendar:services');
      if (!rootGetters['calendar/isReachingStatus']) dispatch('calendar/checkMeeting', null, { root: true });
    });

    getters.socket.on('appointments:find', appointments => {
      commit('calendar/appointments', appointments, { root: true });
    });

    getters.socket.on('appointments:notification:new', event => {
      document.dispatchEvent(new CustomEvent('appointments:find'));
      commit('calendar/new', event, { root: true });
    });

    getters.socket.on('appointments:meeting', event => {
      commit('calendar/inMeeting', event, { root: true });
      commit('calendar/latestMeeting', new Date(event.endAt), { root: true });
    });

    getters.socket.on('appointments:get', appointment => {
      commit('calendar/appointmentData', appointment, { root: true });
      if (!rootGetters['calendar/openAppointmentDrawer']) commit('calendar/openAppointmentDrawer', true, { root: true });
    });

    getters.socket.on('calendar:services', services => {
      commit('calendar/services', services, { root: true });
    });

    getters.socket.on('meeting:join', (event) => {
      const { accessToken, agentForm, recordingToken, recordingsSettings, mandatoryTags = false, tags = [] } = event;
      const { _id, extraData = {}, form = [], comment = '' } = rootGetters['calendar/appointmentData'];
      commit('measureNetwork', false);
      commit('call/recordingToken', recordingToken, { root: true });
      commit('call/recordingsSettings', recordingsSettings || {}, { root: true });
      commit('call/signalingToken', accessToken, { root: true });
      commit('calendar/onMeeting', true, { root: true });
      commit('call/id', _id, { root: true });
      commit('call/extraData', extraData, { root: true });
      commit('call/form', form, { root: true });
      commit('call/agentForm', agentForm, { root: true });
      commit('call/tags', tags, { root: true });
      commit('call/comments', comment ? comment.text : '', { root: true });
      commit('call/mandatoryTags', mandatoryTags, { root: true });
      commit('call/active', true, { root: true });
    });

    getters.socket.on('meeting:ended', () => {
      const { _id } = rootGetters['calendar/appointmentData'] || {};
      document.dispatchEvent(new CustomEvent('hangup-view'));
      dispatch('calendar/getAppointment', _id, { root: true });
      commit('calendar/inMeeting', null, { root: true });
      dispatch('call/CleanEndedCall', null, { root: true });
      commit('measureNetwork', true);
      dispatch('networkBackgroundCheck');
    });

    getters.socket.on('meeting:hangup:error', event => {
      document.dispatchEvent(new CustomEvent('hangup:error', { detail: event }));
    });

    getters.socket.on('authenticate:error', async (event) => {
      const { code, message } = event;
      const { refreshTokenExpired } = getters;
      if (code === 403) {
        document.dispatchEvent(new CustomEvent('session:duplicated'));
        window.sessionDuplicated = true;
      }
      commit('disconnected', true);
      commit('available', false);
      commit('call/active', false, { root: true });
      commit('call/inbound', false, { root: true });
      document.dispatchEvent(new Event('offline:play'));
      console.error(event);
      if (code === 401) {
        await auth.renew();
        dispatch('connect');
      }
      if (refreshTokenExpired) new NotificationAPI().send('Tu sesión ha expirado', 'Vuelve a iniciar sesión con tus credenciales.');
    });

    getters.socket.on('console:settings', settings => {
      commit('settings', settings);
      dispatch('calendar/checkMeeting', null, { root: true });
    });

    getters.socket.on('agent:status:update', event => {
      const {
        online,
        available,
        inCall,
      } = event;
      if (online) timer.start();
      else timer.pause();

      commit('disconnected', !online);
      const customEvent = new CustomEvent('status-changed', { detail: event });
      document.dispatchEvent(customEvent);
      if (online || available || inCall) document.dispatchEvent(new Event('offline:pause'));
    });

    getters.socket.on('agent:call:timeout', () => {
      document.dispatchEvent(new Event('inbound:pause'));
      commit('call/inbound', false, { root: true });
      commit('callTimeout', true);
    });

    getters.socket.on('agent:call:exceed', () => {
      document.dispatchEvent(new Event('inbound:pause'));
      commit('call/inbound', false, { root: true });
    });

    getters.socket.on('agent:call:inbound', event => {
      const { segment, duration, previousSegment } = event;
      commit('call/inboundData', event, { root: true });
      const readableHour = new HumanizeDate().toLocale(new Date(), {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
      new NotificationAPI().send('📞 Llamada entrante', `Segmento: ${segment} \n\n Tiempo en fila: ${duration} minutos. Recibida a las ${readableHour.split(' ')[1]}`);
      commit('call/segment', segment, { root: true });
      if (previousSegment) commit('call/previousSegment', previousSegment, { root: true });
      commit('call/time_in_queue', duration, { root: true });
      commit('call/inbound', true, { root: true });
      document.dispatchEvent(new Event('inbound:play'));
    });

    getters.socket.on('agent:call:invited', event => {
      const { agent } = event;
      document.dispatchEvent(new Event('inbound:play'));
      commit('call/agent', agent, { root: true });
      commit('call/inbound', true, { root: true });
      new NotificationAPI().send('Invitación entrante', `${agent} te esta invitando a una llamada.`);
    });

    getters.socket.on('agent:call:transferred', event => {
      const { agent } = event;
      new NotificationAPI().send('Llamada transferida entrante', `${agent} te ha transferido una llamada.`);
      document.dispatchEvent(new Event('inbound:play'));
      commit('call/agent', agent, { root: true });
      commit('call/transferred', true, { root: true });
      commit('call/inbound', true, { root: true });
    });

    getters.socket.on('agent:call:join', event => {
      const { call, extraData, form = [], tags = [], accessToken, recordingsSettings = {}, agentForm, recordingToken, mandatoryTags = false } = event;
      window.isCallActive = true;
      commit('measureNetwork', false);
      commit('call/id', call, { root: true });
      commit('call/recordingToken', recordingToken, { root: true });
      commit('call/recordingsSettings', recordingsSettings || {}, { root: true });
      commit('call/signalingToken', accessToken, { root: true });
      commit('call/extraData', extraData, { root: true });
      commit('call/form', form, { root: true });
      commit('call/agentForm', agentForm, { root: true });
      commit('call/callData', event, { root: true });
      commit('call/active', true, { root: true });
      commit('call/tags', tags, { root: true });
      commit('call/mandatoryTags', mandatoryTags, { root: true });
    });

    getters.socket.on('agent:call:rejoin', event => {
      const {
        call, extraData, form = [], comment, tags, selectedTags, accessToken, recordingsSettings = {}, agentForm, recordingToken
      } = event;
      if (rootGetters['call/active']) return;
      window.isCallActive = true;
      commit('measureNetwork', false);
      commit('call/id', call, { root: true });
      commit('call/recordingToken', recordingToken || {}, { root: true });
      commit('call/recordingsSettings', recordingsSettings || {}, { root: true });
      commit('call/signalingToken', accessToken, { root: true });
      commit('call/extraData', extraData, { root: true });
      commit('call/form', form, { root: true });
      commit('call/agentForm', agentForm, { root: true });
      commit('call/comments', comment, { root: true });
      commit('call/tags', tags, { root: true });
      commit('call/selectedTags', selectedTags, { root: true });
      commit('call/callData', event, { root: true });
      commit('call/active', true, { root: true });
      commit('reJoined', true);
    });

    getters.socket.on('answer:error', event => {
      const { code } = event;
      commit('call/inbound', false, { root: true });
      if (code !== 422) commit('errorAnswering', true);
      else commit('customerLeaveBefore', true);
    });

    getters.socket.on('agent:call:end', () => {
      window.isCallActive = false;
      if (getters.webrtcInstance) getters.webrtcInstance.destroy(false);
      document.dispatchEvent(new CustomEvent('hangup-view'));
      dispatch('call/CleanEndedCall', null, { root: true });
      commit('measureNetwork', true);
      dispatch('networkBackgroundCheck');
    });

    getters.socket.on('hangup:error', event => {
      document.dispatchEvent(new CustomEvent('hangup:error', { detail: event }));
    });

    getters.socket.on('agent:call:customer:timeout', () => commit('customerTimeout', true));

    getters.socket.on('customer:disconnect:queue', event => {
      const { reason } = event;
      if (['ping-timeout', 'transport-error', 'queue-assigned'].includes(reason)) return;
      // Add message to indicate that customer disconnect by network issues
      commit('call/inbound', false, { root: true });
      commit('call/client_out', true, { root: true });
    });

    getters.socket.on('agent:call:customer:join', () => commit('customerJoined', true));
    getters.socket.on('customer:disconnect:call', ({ reason }) => {
      document.dispatchEvent(new CustomEvent('customer:disconnect', { detail: reason }));
      commit('customerDisconnected', reason);
    });

    getters.socket.on('customer:hangup', () => commit('call/client_leave', true, { root: true }));

    getters.socket.on('error', (error) => {
      console.error('Error trying to connect', error.message, 'Session id', getters.sessionId);
      // new NotificationAPI().send('😱 Error en la conexión 😱', 'Hemos detectado un error en la conexión, intenta recargando.');
      commit('disconnected', true);
      commit('available', false);
    });

    getters.socket.on('transfer:segment:ok', () => commit('transfer_successful', {
      status: true,
      reason: 'Transferencia realizada, la llamada terminará en 10 segundos.'
    }));
    getters.socket.on('transfer:agent:answered', () => commit('transfer_successful', {
      status: true,
      reason: 'Llamada transferida al agente correctamente.'
    }));

    getters.socket.on('call:transfer:segment:error', event => {
      const { code } = event;
      if (code === 422) {
        commit('transferSegmentFail', {
          status: true,
          reason: 'No hay agentes conectados en el segmento.'
        });
      }
      if (code === 400) {
        commit('transferSegmentFail', {
          status: true,
          reason: 'Ups algo ha salido mal, intenta de nuevo. (Hemos recibido el error)'
        });
      }
    });


    getters.socket.on('customer:permissions', () => commit('customerPermissions', {
      status: true,
      reason: 'El cliente ha denegado el permiso de acceso a la cámara y/o micrófono.'
    }));
    getters.socket.on('transfer:agent:dismissed', () => commit('transferAgentFail', {
      status: true,
      reason: 'El agente rechazó la llamada de transferencia.'
    }));
    getters.socket.on('transfer:agent:timeout', () => commit('transferAgentFail', {
      status: true,
      reason: 'El agente no ha respondido a la llamada de transferencia.'
    }));
    getters.socket.on('call:transfer:agent:error', () => commit('transferAgentFail', {
      status: true,
      reason: 'El agente no está disponible, talvez entró a una llamada o se desconectó.'
    }));

    getters.socket.on('invite:agent:answered', () => commit('invitation_successful', {
      status: true,
      reason: 'El agente ha contestado la invitación, uniéndose...'
    }));
    getters.socket.on('invite:agent:dismissed', () => commit('invitation_failed', {
      status: true,
      reason: 'El agente ha rechazado la invitación.'
    }));
    getters.socket.on('invite:agent:timeout', () => commit('invitation_failed', {
      status: true,
      reason: 'El agente no ha respondido a la llamada.'
    }));
    getters.socket.on('call:invite:agent:error', () => commit('invitation_failed', {
      status: true,
      reason: 'El agente no está disponible, talvez entró a una llamada o se desconectó.'
    }));
    getters.socket.on('invite:agent:hangup', () => commit('invitedAgentHangup', true));

    getters.socket.on('comments_updated', ({ date }) => commit('commentsTime', date));

    getters.socket.on('beamport:create', event => {
        const component = event.channel === 'files' ? 'fileshare' : 'capture' ;
      commit('components/view', component, { root: true });
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent(`beamport:files:connect:${event.channel}`, { detail: event }));
      }, 1000);
    });

  },
  status({ getters }, status) {
    getters.socket.emit('status', { status });
  },
  LoadResources() {
    // dispatch('privileges');
  },
  parseFetchTable({}, objTable) {
    // Function for return parsed skip key
    const parsePage = (limit = 10, page = 0) => ({
      $skip: limit * ((page === 0) ? page : page - 1),
    });
    // Function for return parsed sort key
    const parseSort = (key = '_id', sort = 'ascend') => ({ [`$sort[${key}]`]: (sort === 'ascend') ? 1 : -1 });
    // Start ParamsParse
    const parser = new ParamsParser();
    const obj = { ...objTable };
    const sort = (typeof objTable.sortField !== 'undefined') ? parseSort(objTable.sortField, objTable.sortOrder) : {};
    const limit = parsePage(objTable.results, objTable.page);
    // Delete unnecessary keys
    delete obj.sortOrder;
    delete obj.sortField;
    delete obj.results;
    delete obj.page;
    // If is array
    Object.keys(obj).map(v => (Array.isArray(obj[v]))
      && delete Object.assign(obj, {
        [(v.includes('[$in]') && !v.includes('firstname')) ? v : `${v}[$in]`]: obj[v],
      })[v]);
    parser.byObj({ ...sort, ...limit, ...obj });
    return parser.parse();
  },
  async getUserData({ commit }) {
    const response = await axios.get(`users/${state.id}?$populate[0]=account`);
    commit('email', response.data.email);
    commit('metaData', response.data);
  },
};

const getters = {
  account: state => state.account,
  id: state => state.id,
  email: state => state.email,
  socket: state => state.socket,
  token: state => state.token,
  available: state => state.available,
  disconnected: state => state.disconnected,
  segments: state => state.segments,
  agents: state => state.agents,
  forms: state => state.forms,
  transfer_successful: state => state.response.transfer_successful,
  transfer_failed: state => state.response.transfer_failed,
  invitation_successful: state => state.response.invitation_successful,
  invitation_failed: state => state.response.invitation_failed,
  transferAgentFail: state => state.response.transferAgentFail,
  transferSegmentFail: state => state.response.transferSegmentFail,
  time: state => state.time,
  interval: state => state.interval,
  reJoined: state => state.reJoined,
  customerTimeout: state => state.customerTimeout,
  customerJoined: state => state.customerJoined,
  customerDisconnected: state => state.customerDisconnected,
  invitedAgentHangup: state => state.invitedAgentHangup,
  connectionError: state => state.connectionError,
  callTimeout: state => state.callTimeout,
  errorAnswering: state => state.errorAnswering,
  hangupError: state => state.hangupError,
  customerLeaveBefore: state => state.customerLeaveBefore,
  metaData: state => state.metaData,
  settings: state => state.settings,
  userSettings: state => state.userSettings,
  refreshTokenExpired: state => state.refreshTokenExpired,
  sessionId: state => state.sessionId,
  forcedDisconnected: state => state.forcedDisconnected,
  disconnectReason: state => state.disconnectReason,
  disconnectionLogs: state => state.disconnectionLogs,
  measureNetwork: state => state.measureNetwork,
  jitter: state => state.jitter,
  numberOfDisconnections: state => state.numberOfDisconnections,
  isHTTPNetwork: state => state.isHTTPNetwork,
  virtualBackgroundGlobal: state => state.virtualBackgroundGlobal,
  globalCameraStream: state => state.globalCameraStream,
  webrtcInstance: state => state.webrtcInstance,
  notifications: state => state.notifications,
  customerPermissions: state => state.customerPermissions,
  statusHangup: state => state.statusHangup,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
