<template>
  <a-table
    :columns="columns"
    :row-key="record => record.id"
    :data-source="data"
    :pagination="pagination"
    :loading="loading"
    @change="handleData"
    :row-class-name="dynamicClassName"
    :locale="$store.getters['global/table_locale']"
  >
    <div
      slot="filterAgent"
      slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters }"
      style="padding: 8px"
    >
      <a-input
        v-ant-ref="c => filterName = c"
        :placeholder="$t('call.transfer.search.agent')"
        :value="selectedKeys[0]"
        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
        @pressEnter="() => handleSearchAgent(selectedKeys, confirm)"
        style="width: 188px; margin-bottom: 8px; display: block;"
      />
      <a-button
        type="primary"
        @click="() => handleSearchAgent(selectedKeys, confirm)"
        icon="search"
        size="small"
        style="width: 90px; margin-right: 8px;"
      >
        {{ $t('search') }}
      </a-button>
      <a-button
        @click="() => handleReset(clearFilters)"
        size="small"
        style="width: 90px"
      >
        {{ $t('clean') }}
      </a-button>
    </div>

    <template
      v-if="entity === 'agents'"
      v-slot:status="obj"
    >
      <a-tag :color="status(obj).color">
        {{ status(obj).text }}
      </a-tag>
    </template>
    <template
      v-if="entity === 'agents'"
      v-slot:name="id"
    >
      <div>{{ fullname(id) }}</div>
    </template>
    <template
      v-slot:action="id"
    >
      <a-button
        @click="selected(id)"
        :disabled="disable(id)"
      >
        {{ $t('select') }}
      </a-button>
    </template>
  </a-table>
</template>

<script>
export default {
  props: {
    entity: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    query: {},
    pagination: {},
    filterName: '',
    filtering: false,
  }),
  computed: {
    data() {
      return this.$store.getters[`global/${this.entity}`].data;
    },
  },
  watch: {
    data() {
      const { total, limit } = this.$store.getters[`global/${this.entity}`];
      this.pagination = { total, pageSize: limit };
      this.loading = false;
    },
  },
  async mounted() {
    await this.handleData();
  },
  methods: {
    async handleData(pagination = { ...this.pagination }, filters, sorter) {
      if (this.filtering) return;
      this.loading = true;
      const pager = { ...this.pagination };
      pager.current = pagination.current || 0;
      this.pagination = pager;
      this.query = (filters) ? {
        results: (pagination) ? pagination.pageSize : this.query.results,
        page: (pagination) ? pagination.current : this.query.page,
        sortField: (sorter) ? sorter.field : this.query.sortField,
        sortOrder: (sorter) ? sorter.order : this.query.sortOrder,
        ...filters,
      } : this.query;
      const queries = await this.$store.dispatch('global/parseFetchTable', this.query).catch(e => e);
      await this.$store.dispatch(`global/${this.entity}`, queries).catch(e => e);
    },
    selected(id) {
      this.select(this.entity, id);
    },
    fullname(id) {
      const { firstname, lastname } = this.$store.getters['global/agents'].data.find(x => x.id === id);
      return `${firstname} ${lastname}`;
    },
    status(object = {}) {
      const { online, available, inCall } = object;
      if (online && available) return { color: 'green', text: this.$t('status.online') };
      else if (online && !available && !inCall) return { color: 'yellow', text: this.$t('status.occupied') };
      else if (online && !available && inCall) return { color: 'yellow', text: this.$t('status.incall') };
      else if (!online) return { color: 'red', text: this.$t('status.disconnected') };
      return '';
    },
    disable(id) {
      if (this.entity === 'segments') return false;
      const { status } = this.$store.getters['global/agents'].data.find(x => x.id === id);
      return !status.online || !status.available;
    },
    dynamicClassName(record) {
      const { id } = record;
      if (id === this.$store.getters['global/id']) return 'hide-row';
      return '';
    },
    handleSearchAgent(selectedKeys, confirm) {
      this.filtering = true;
      this.loading = true;
      const value = selectedKeys;
      // Search agent
      this.$store.dispatch('global/agents', `?$or[0][firstname][$regex]=${value}&$or[0][firstname][$options]=i&$or[1][lastname][$regex]=${value}&$or[1][lastname][$options]=i`);
      confirm();
    },
    handleReset(clearFilters) {
      this.filtering = false;
      clearFilters();
      this.filterName = '';
    },
  },
};
</script>

<style>
.hide-row {
  display: none;
}
</style>
