<template>
  <div class="container-form" />
</template>

<script>
import FormsSDK from '@videsk/forms';
import _debounce from 'lodash/debounce';

export default {
  name: 'agentform',
  computed: {
    formData() {
      return this.$store.getters['call/agentForm'];
    },
    socket() {
      return this.$store.getters['global/socket'];
    },
  },
  mounted() {
    this.loadForm();
    this.debounce = _debounce(this.saveForm.bind(this), 500);
  },
  methods: {
    loadForm() {
      this.form = new FormsSDK({ target: document.querySelector('.container-form') });
      this.$store.commit('call/formInstance', this.form);
      this.form.on('updated', () => this.debounce());
      this.form.render(this.formData);
    },
    saveForm() {
      this.socket.emit('form:update', { values: this.form.data });
    },
  },
};
</script>

<style>
.container-form {
  width: 100%;
  padding: 1rem;
}
</style>
