<template>
  <div class="vd-login-container">
    <div class="vd-header-login">
      <div class="vd-logo-login" />
    </div>


    <div class="vd-login-form">
      <div class="vd-login-inputs">
        <a-input
          v-show="!showOtp"
          type="text"
          class="vd-login joined"
          :placeholder="$t('login.placeholder.email')"
          v-model="email"
          :auto-focus="true"
          @blur="verifyEmail"
        />

        <a-input
          v-show="!showOtp"
          type="password"
          class="vd-login joined vd_suffix"
          :placeholder="$t('login.placeholder.password')"
          v-model="password"
        />
        <p
          v-show="showOtp"
          style="cursor: pointer; margin-bottom: 10px;"
          @click="showOtp = false"
        >
          <a-icon
            type="left"
            style="height: 0.8rem; margin-right: 5px;"
          />Volver
        </p>
        <a-input
          v-show="showOtp"
          type="text"
          class="vd-login vd_suffix"
          :placeholder="$t('login.code2fa')"
          v-model="otp"
        />
      </div>

      <div
        v-show="mailCheckedEmail && !showOtp"
        class="vd-email-suggestion"
      >
        {{ $t('login.suggestionEmail') }} <span @click="setEmail">{{ mailCheckedEmail }}</span>?
      </div>

      <div
        style="padding: 10px 0 20px 0;"
        v-show="!showOtp"
      >
        <a-checkbox
          type="checkbox"
          v-model="remember"
          class="checkbox-container"
        >
          {{ $t('login.placeholder.remember') }}
        </a-checkbox>

        <a-checkbox
          type="checkbox"
          v-model="termsCheck"
          class="checkbox-container"
        >
          {{ $t('login.placeholder.terms') }}
          <a
            href="http://policies.videsk.io"
            target="_blank"
          >
            {{ $t('login.placeholder.terms2') }}
          </a>
        </a-checkbox>
      </div>

      <a-button
        class="vd-login text-center"
        :loading="loading"
        @click="login"
      >
        {{ $t('login.button') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import Mailcheck from 'mailcheck';
import Pendo from '@/plugins/pendo';

export default {
  data() {
    return {
      showOtp: false,
      otp: '',
      email: '',
      password: '',
      remember: false,
      termsCheck: false,
      loading: false,
      errors: {
        email: false,
        password: false,
      },
      mailCheckedEmail: undefined,
    };
  },
  methods: {
    async login() {
      if (!this.termsCheck) return this.$Notify.error({ showClose: true, message: this.$t('login.errors.terms') });
      this.loading = true;
      const response = await this.$http.post('auth/login', {
        email: this.email, password: this.password, agreement: this.termsCheck, otp: this.otp
      }).catch(e => e);
      this.loading = false;
      if (response instanceof Error) return this.errorOnLogin(response);

      const { level, accessToken, refreshToken } = response.data;
      if (level < 5) return this.redirectToDashboard();

      await this.$authorization.login(accessToken, refreshToken, this.remember);
      this.setAuthorizationHeader();

      this.$Notify.success({ showClose: true, message: this.$t('login.success') });
      this.loading = false;

      await this.getUserMetadata();
      this.mixpanel();

      document.dispatchEvent(new CustomEvent('create-calendar'));

      return this.loading;
    },
    async getUserMetadata() {
      const decode = JWT => JSON.parse(atob(JWT.split('.')[1]));
      const accessToken = this.$authorization.getTokens('accessToken');
      const { aud, org } = decode(accessToken);
      this.$store.commit('global/account', org);
      this.$store.commit('global/id', aud);
      return this.$store.dispatch('global/getUserData');
    },
    wait(timeout = 1000) {
      return new Promise(resolve => setTimeout(resolve, timeout));
    },
    redirectToDashboard() {
      this.$Notify.error({ message: this.$t('login.redirect') });
      setTimeout(() => window.location.replace('https://app.videsk.io'), 10000);
    },
    errorOnLogin(error) {
      const { status } = error.response || {};
      if (!status) this.$Notify.error({ message: this.$t('login.errors.internet') });
      else if (status === 401) this.$Notify.error({ showClose: true, message: this.$t('login.errors.match') });
      else if (status === 402) this.$Notify.error({ message: this.$t('login.errors.subscription') });
      else if (status === 403) this.$Notify.error({ message: this.$t('login.errors.ip') });
      else if (status === 406) this.showOtp = true;
      else if (status === 503) this.$Notify.error({ message: this.$t('login.errors.ip') });
      else this.$Notify.error({ message: this.$t('login.errors.server') });
    },
    setAuthorizationHeader() {
      this.axios.defaults.headers.common.Authorization = `Bearer ${this.$authorization.getTokens('accessToken')}`;
    },
    verifyEmail() {
      const self = this;
      Mailcheck.run({
        email: self.email,
        suggested(suggestion) {
          self.mailCheckedEmail = suggestion.full;
        },
        empty() {},
      });
    },
    setEmail() {
      this.email = this.mailCheckedEmail;
      this.mailCheckedEmail = undefined;
    },
    mixpanel() {
      const {
        email, account, firstname, lastname
      } = this.$store.getters['global/metaData'] || {};
      this.$mixpanel.identify(this.$store.getters['global/id']);
      this.$mixpanel.set_group('company', account.name);
      this.$mixpanel.people.set({
        $first_name: firstname,
        $last_name: lastname,
        $email: email,
        Business: account.name,
      });
    },
    setPendo() {
      const {
        id,
        firstname,
        lastname,
        email,
        account,
      } = this.$store.getters['global/metaData'];
      Pendo(
        {
          id,
          lastname,
          firstname,
          email,
        },
        {
          id: account._id,
          name: account.name,
        },
      );
    },
  },
};
</script>

<style>
.checkbox-container {
  padding: 15px 0 0 0 !important;
  margin: 0 !important;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center > span {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
</style>
